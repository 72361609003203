import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import "./Auth.scss";
import Cookie from "universal-cookie";
import { useEffect } from "react";
import axios from "axios";
import ResetPassword from "./ResetPassword";

const cookie = new Cookie();
export default function Auth({ global }) {
  function validateSession(session, successCallback) {
    if (!cookie.get("session")) return;
    axios({
      method: "POST",
      url: global.api.base + "/auth/validate-session",
      data: {
        session,
      },
    })
      .catch((err) => {})
      .then((response) => {
        if (response.status === 200) {
          if (window.location.pathname.includes("/auth")) {
            successCallback();
          }
        }
      });
  }

  useEffect(() => {
    const session = cookie.get("session");
    if (session) {
      validateSession(session, () => {
        if (window.location.pathname.includes("/auth")) {
          window.location.href = "/dashboard";
        }
      });
    }
  }, []);

  return (
    <Router basename="/auth">
      <div className="auth">
        <Routes>
          <Route path="/" element={<Login global={global} />} />
          <Route
            path="/login"
            element={
              <Login
                onNewSession={(sess) => {
                  cookie.set("session", sess, {
                    path: "/",
                  });
                  window.location.href = "/dashboard";
                }}
                global={global}
              />
            }
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword global={global} />}
          />
          <Route
            path="/reset-password/:token"
            element={<ResetPassword global={global} />}
          />
        </Routes>
      </div>
    </Router>
  );
}
