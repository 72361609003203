import { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import html2canvas from "html2canvas";
import moment from "moment";
import "moment/locale/id";

export default function Download({ el, epoch }) {
  const [downloading, setDownloading] = useState(false);
  function handleDownload() {
    const element = window.document.querySelector(".graph_income .line");
    setDownloading(true);
    exportAsImage(
      element,
      "Grafik " + moment(epoch * 1000).format("MMMM YYYY") + ".png"
    );
  }

  async function exportAsImage(el, imagefilename) {
    const canvas = await html2canvas(el);
    const blobImage = canvas.toDataURL("image/png", 1.0);
    const fakeLink = document.createElement("a");
    fakeLink.download = imagefilename;
    fakeLink.href = blobImage;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    setDownloading(false);
  }

  return (
    !downloading && (
      <Tooltip title="Download sebagai gambar">
        <IconButton onClick={handleDownload} className="download-btn">
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    )
  );
}
