import noneImage from "../../Static/Images/Empty-amico.png";
import moment from "moment-timezone";
import "moment/locale/id";

export default function Data({ data, epoch }) {
  return (
    <div className="data">
      {data.length < 1 && <img src={noneImage} className="no-data" />}
      {data.length >= 1 && (
        <table>
          <thead>
            {epoch && (
              <tr className="parsed-data-container">
                <td colSpan={9} className="parsed-data-string">
                  {moment(epoch.start * 1000).format("dddd, DD-MM-YYYY HH:mm")}{" "}
                  - {moment(epoch.end * 1000).format("dddd, DD-MM-YYYY HH:mm")}
                </td>
              </tr>
            )}
            <tr>
              <td>#</td>
              <td>Invoice</td>
              <td>Produk</td>
              <td>Toko</td>
              <td>Pembeli</td>
              <td>Harga Jual</td>
              <td>Harga Modal</td>
              <td>Profit</td>
              <td>Admin</td>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.kode_invoice}</td>
                <td>
                  <div className="product">
                    <div className="pcs">{row.produk}x</div>
                    <div className="urls">
                      {row.url_produk.split("$$$$").map((url, index) => (
                        <a
                          key={index}
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                          className="url"
                        >
                          {url}
                        </a>
                      ))}
                    </div>
                  </div>
                </td>
                <td className="store" data-store={row.toko}>
                  {row.toko}
                </td>
                <td className="buyer" data-buyer={row.pembeli}>
                  {row.pembeli}
                </td>
                <td>
                  <div className="price" data-negative={row.harga_jual < 0}>
                    {row.harga_jual < 0 ? "- " : ""}
                    {formatRupiah(row.harga_jual.toFixed(0), "Rp")}
                  </div>
                </td>
                <td>
                  <div className="price" data-negative={row.harga_modal < 0}>
                    {row.harga_modal < 0 ? "- " : ""}
                    {formatRupiah(row.harga_modal.toFixed(0), "Rp ")}
                  </div>
                </td>

                <td>
                  <div
                    className="price"
                    data-negative={row.harga_jual - row.harga_modal < 0}
                  >
                    {row.harga_jual - row.harga_modal < 0 ? "- " : ""}
                    {formatRupiah(
                      (row.harga_modal - row.harga_jual).toFixed(0),
                      "Rp"
                    )}
                  </div>
                </td>
                <td className="created" data-epoch={row.created}>
                  {row.admin}
                </td>
              </tr>
            ))}
            <tr className="subtotal">
              <td className="title" colSpan={5}>
                SUBTOTAL
              </td>
              <td className="subtotal-value">
                {formatRupiah(getTotal(data.map((x) => x.harga_jual)), "Rp")}
              </td>
              <td className="subtotal-value">
                {formatRupiah(getTotal(data.map((x) => x.harga_modal)), "Rp")}
              </td>
              <td className="subtotal-value" colSpan={2}>
                {formatRupiah(
                  getTotal(data.map((x) => x.harga_jual - x.harga_modal)),
                  "Rp"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

function getTotal(numbers) {
  var i = 0;
  for (var number of numbers) {
    i += number;
  }
  return i.toFixed(0).toString();
}

function formatRupiah(angka, prefix) {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
