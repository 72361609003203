import { TextField, Button, Link, Alert, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login({ global, onNewSession }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorField, setErrorField] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  // ! Event handler
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleLogin = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/auth/login",
      data: {
        email,
        password,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.data.error.state) {
          setErrorField(response.data.error.error_field);
          setErrorMessage(response.data.error.message);
        } else {
          onNewSession(response.data.session);
        }
      })
      .catch((err) => {
        setErrorField("");
        setLoading(false);
        setErrorMessage(err.message);
      });
  };

  useEffect(() => {
    document.title = `Sign in - ${global.appname}`;
    if (location.pathname == "/") {
      navigate("/login");
    }
  }, []);

  return (
    <div className="login form">
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
      )}
      <img src={global.icon} className="icon" alt={global.appname} />
      <div className="header">
        <div className="title">Sign in</div>
        <div className="subtitle">
          Silahkan login untuk melanjutkan sesi anda
        </div>
      </div>
      {errorField == "" && errorMessage != "" && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          {errorMessage}
        </Alert>
      )}
      <div className="body">
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Email"
          type="email"
          autoFocus
          disabled={loading}
          value={email}
          onChange={handleEmailChange.bind(this)}
          error={errorField == "email"}
          helperText={errorField == "email" ? errorMessage : ""}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              handleLogin();
            }
          }}
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Password"
          type="password"
          value={password}
          error={errorField == "password"}
          helperText={errorField == "password" ? errorMessage : ""}
          onChange={handlePasswordChange.bind(this)}
          disabled={loading}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              handleLogin();
            }
          }}
        />
        <div className="actions">
          <Link
            component="button"
            className="left"
            variant="body2"
            underline="hover"
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            Lupa password ?
          </Link>
          <Button
            className="right"
            size="small"
            variant="contained"
            disabled={loading}
            onClick={handleLogin.bind(this)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
