import CoinImage from "../../Static/Images/coin.gif";
import Button from "@mui/material/Button";

export default function RegardBonus({ open, onClose, bonus }) {
  return (
    open && (
      <>
        <div className="regard-bonus-wrapper"></div>
        <div className="regard-bonus">
          <img
            className="coin-animation"
            alt="Coin animation"
            src={CoinImage}
          />
          <div className="title">Selamat ...</div>
          <p className="subtitle">
            Bonus sebanyak <b>{formatRupiah(bonus, "Rp ")}</b> (3%) dari profit
            penjualan ini telah ditambahkan ke estimasi pendapatanmu, semangat
            ya...
          </p>
          <Button
            sx={{
              background: "#FDD503 !important",
              color: "#FFFDEB",
              display: "flex",
              margin: "auto",
            }}
            onClick={onClose}
          >
            Tutup
          </Button>
        </div>
      </>
    )
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
