import { Link, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Card,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useEffect, useState } from "react";
import Cookie from "universal-cookie";
import axios from "axios";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import StorageIcon from "@mui/icons-material/Storage";
import SignLanguageIcon from "@mui/icons-material/SignLanguage";
import OrderIcon from "@mui/icons-material/MonetizationOnTwoTone";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import FlagIcon from "@mui/icons-material/Flag";
import ShareIcon from "@mui/icons-material/Share";
import TimelineIcon from "@mui/icons-material/Timeline";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import SplitscreenIcon from '@mui/icons-material/Splitscreen';

const cookie = new Cookie();
const menus = [
  {
    url: "/",
    name: "Dashboard",
    icon: <DashboardIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/order",
    name: "New Order",
    icon: <OrderIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/pembukuan-harian",
    name: "Finish Order",
    icon: <LibraryBooksIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/order-cancelation",
    name: "Cancel Order",
    icon: <LibraryBooksIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/pembukuan-bulanan",
    name: "Laporan Bulanan",
    icon: <LibraryBooksIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/akun",
    name: "Akun",
    icon: <StoreMallDirectoryIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/admin",
    name: "Admin",
    icon: <SupervisorAccountIcon sx={{ fontSize: "20px" }} />,
    superadmin: true,
  },
  {
    url: "/backup-database",
    name: "Backup Database",
    icon: <StorageIcon sx={{ fontSize: "20px" }} />,
    superadmin: true,
  },

  {
    url: "/account-performance",
    name: "Performa Akun",
    icon: <ScatterPlotIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/share-profit",
    name: "Share Profit",
    icon: <ShareIcon sx={{ fontSize: "20px" }} />,
    superadmin: true,
  },
  {
    url: "/graph-income",
    name: "Grafik",
    icon: <TimelineIcon sx={{ fontSize: "20px" }} />,
  },
  {
    url: "/monitor-pending-order",
    name: "[M] New Order",
    icon: <RemoveRoadIcon sx={{ fontSize: "20px" }} />,
    superadmin: true,
  },
  {
    url: '/manage-admin-task',
    name: 'Kelola Tugas Admin',
    icon: <SplitscreenIcon sx={{ fontSize: '20px' }} />,
    superadmin: true
  },
  {
    url: "/my-task",
    name: "Tugasku",
    icon: <SplitscreenIcon sx={{ fontSize: '20px' }} />,
    superadmin: false
  }
];

export default function Sidebar({ navOpen, global, onNavClose }) {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(0);
  const [userRole, setUserRole] = useState("ADMIN");

  useEffect(() => {
    setActiveMenu(
      menus.findIndex(
        (x) => x.url === location.pathname.split("?")[0].split("#")[0]
      )
    );
    const i = menus.findIndex(
      (x) => x.url === location.pathname.split("?")[0].split("#")[0]
    );
    var t = "";
    if (i >= 0) {
      t = menus[i].name;
    }
    document.title = t === "" ? global.appname : t + " - " + global.appname;
    onNavClose();
  }, [location]);

  useEffect(() => {
    // Get the profile detail
    const session = cookie.get("session");
    if (session) {
      axios({
        method: "POST",
        url: global.api.base + "/main/profile-detail",
        data: {
          session,
        },
      }).then((response) => {
        const data = response.data.data;
        setUserRole(data.role);
      });
    }
  }, []);

  return (
    <>
      <Card className={`sidebar${navOpen ? " open" : ""}`}>
        <List className="list">
          {menus.map((menu, i) => (
            <ListItem
              className={`item${activeMenu === i ? " active" : ""}`}
              key={i}
              disablePadding
              sx={{
                display:
                  userRole === "SUPER_ADMIN"
                    ? ""
                    : menu.superadmin
                    ? "none"
                    : "",
              }}
            >
              <Link className="link" to={menu.url}>
                <ListItemButton sx={{ display: "flex", alignItems: "center" }}>
                  <ListItemAvatar sx={{ mr: -3 }}>{menu.icon}</ListItemAvatar>
                  <ListItemText>
                    <div className="menu-name">{menu.name}</div>
                  </ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Card>
      <div
        onClick={onNavClose}
        className={`sidebar-wrapper${navOpen ? " open" : ""}`}
      ></div>
    </>
  );
}
