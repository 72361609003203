import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import UnlockIcon from "@mui/icons-material/LockOpen";
import { Tooltip, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { IconButton } from "@mui/material";
import global from "../../Global";
import ConfirmDialog from "../../Component/ConfirmDialog";
import DateIcon from "@mui/icons-material/CalendarMonth";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PendapatanStatis from "./PendapatanStatis";
import RotateLeftTwoToneIcon from "@mui/icons-material/RotateLeftTwoTone";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default function RowOption({ uid, onRefresh }) {
  const [bannedLoadingState, setBannedLoadingState] = useState(false);
  const [bannedState, setBannedState] = useState(false);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [pendapatanStatisOpen, setPendapatanStatisOpen] = useState(false);

  const handleOpenPendapatanStatis = () => setPendapatanStatisOpen(true);
  const handleClosePendapatanStatis = () => setPendapatanStatisOpen(false);

  function getBannedState() {
    setBannedLoadingState(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/banned-state",
      data: {
        uid,
      },
    }).then((response) => {
      setBannedState(response.data.banned);
      setBannedLoadingState(false);
    });
  }

  useEffect(() => {
    getBannedState();
  }, []);

  const handleBannedToggle = (e) => {
    setBannedLoadingState(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/toggle-banned-state",
      data: {
        uid,
      },
    }).then((response) => {
      getBannedState();
    });
  };

  function handleDelete() {
    setDeleteLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/delete-admin",
      data: { uid },
    })
      .then((response) => {
        setDeleteLoading(false);
        onRefresh();
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  }

  const [resetSaldoLoading, setResetSaldoLoading] = useState(false);

  function handleResetSaldoBonus() {
    if (
      window.confirm("Anda yakin ingin melakukan reset terhadap saldo bonus? ")
    ) {
      setResetSaldoLoading(true);
      axios({
        method: "POST",
        data: { uid, session: cookies.get("session") },
        url: global.api.base + "/main/reset-saldo-bonus",
      })
        .then((response) => {
          setResetSaldoLoading(false);
          window.alert(response.data.message);
        })
        .catch((err) => {
          setResetSaldoLoading(false);
          window.alert(err.message);
        });
    }
  }

  return (
    <div className="row-option">
      <PendapatanStatis
        open={pendapatanStatisOpen}
        global={global}
        uid={uid}
        onClose={handleClosePendapatanStatis}
      />
      <ConfirmDialog
        open={confirmDeleteOpen}
        onClose={() => {
          setConfirmDeleteOpen(false);
        }}
        title="Konfirmasi"
        message="Anda yakin ingin menghapus akun ini secara permanen ?"
        onConfirm={handleDelete}
      />

      {deleteLoading && <CircularProgress size="20px" />}

      {!deleteLoading && (
        <Tooltip title="Hapus akun">
          <IconButton
            onClick={() => {
              setConfirmDeleteOpen(true);
            }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

      {bannedLoadingState && <CircularProgress size="20px" />}
      {!bannedLoadingState && (
        <Tooltip title={bannedState ? "Unbanned akun" : "Banned akun"}>
          <IconButton size="small" onClick={handleBannedToggle.bind(this)}>
            {bannedState ? (
              <LockIcon sx={{ color: "red" }} />
            ) : (
              <UnlockIcon sx={{ color: "blue" }} />
            )}
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Atur pendapatan statis">
        <IconButton onClick={handleOpenPendapatanStatis}>
          <MonetizationOnIcon />
        </IconButton>
      </Tooltip>
      {!resetSaldoLoading && (
        <Tooltip title="Reset saldo bonus">
          <IconButton onClick={handleResetSaldoBonus}>
            <RotateLeftTwoToneIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
