import './index.scss'
import axios from 'axios'
import {useEffect, useState} from "react";
import Cookies from 'universal-cookie'
import { TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, Button, TableBody } from '@mui/material'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material'
import moment from 'moment-timezone'

const cookies = new Cookies()

function TimelinedDialog({open, id, onClose}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Timeline</DialogTitle>
            <DialogContent>Hello World</DialogContent>
            <DialogActions>
                <Button variant={'outlined'} onClick={onClose}>Tutup</Button>
            </DialogActions>
        </Dialog>
    )
}

export default function MyTask({global}) {
    const [loading, setLoading] = useState(false)
    const [data, setData]  = useState([])
    const [timelineId, setTimelineId] = useState(1)

    const getData = () => {
        setLoading(true)
        const url = `${global.api.base}/main/my-task-list`
        axios.post(url, { data: { session: cookies.get('session') } })
            .then(({data}) => {
                console.log(data.data)
                setData(data.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <div className={'my-task-page'}>
            <TimelinedDialog
                open={timelineId !== null}
                id={timelineId}
                onClose={() => setTimelineId(null)}
            />
            <TableContainer>
               <Table stickyHeader>
                   <TableHead>
                       <TableRow>
                           <TableCell padding={'checkbox'}>#</TableCell>
                           <TableCell padding={'checkbox'}>Nama</TableCell>
                           <TableCell padding={'checkbox'}>Dibuat & Deadline</TableCell>
                           <TableCell padding={'checkbox'}>Status</TableCell>
                           <TableCell padding={'checkbox'}>Timelines</TableCell>
                           <TableCell padding={'checkbox'}></TableCell>
                       </TableRow>
                   </TableHead>
                   <TableBody>
                       {data.map((task, i) => (
                           <TableRow key={i}>
                               <TableCell padding={'checkbox'}>{i+1}</TableCell>
                               <TableCell padding={'checkbox'}>{task.name}</TableCell>
                               <TableCell padding={'checkbox'}>
                                   <div className={`flex items-center gap-2 py-0.5`}>
                                       <div>
                                           <div className={`rounded px-2 py-0.5 bg-green-200 text-green-600 whitespace-nowrap`}>{moment(task.created * 1000).format('D MMMM YYYY HH:mm')}</div>
                                           <div className={`text-green-700 text-xs`}>{moment(task.created * 1000).fromNow()}</div>
                                       </div>
                                       {task.deadline !== null && (
                                           <div>
                                               <div
                                                   className={`rounded px-2 py-0.5 bg-red-200 text-red-600 whitespace-nowrap`}>{moment(task.deadline * 1000).format('D MMMM YYYY HH:mm')}</div>
                                               <div
                                                   className={`text-red-700 text-xs`}>{moment(task.deadline * 1000).fromNow()}</div>
                                           </div>
                                       )}
                                   </div>
                               </TableCell>
                               <TableCell padding={'checkbox'}>
                                   <div style={{color: task.status.color}} className={`font-bold whitespace-nowrap`}>{ task.status.name }</div>
                                   <div className={`text-xs`}>{ moment(task.status.at * 1000).fromNow() }</div>
                               </TableCell>
                               <TableCell padding={'checkbox'}>
                                   <Button className={`whitespace-nowrap`} size={'small'}>Lihat timeline</Button>
                               </TableCell>
                               <TableCell padding={'checkbox'}>
                                   {!task.finished.state || task.finished.at === null && (
                                       <Button
                                           className={`whitespace-nowrap`}
                                           variant={'contained'}
                                           size={'small'}
                                            disabled={task.finished.state && task.finished.at !== null}
                                            onClick={() => setTimelineId(task.id)}
                                       >Selesaikan</Button>
                                   )}
                               </TableCell>
                           </TableRow>
                       ))}
                   </TableBody>
               </Table>
            </TableContainer>
        </div>
    )
}