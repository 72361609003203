import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState, useEffect } from "react";

export default function MoveDate({ onChange }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value != null) {
      const d = new Date(value);
      const epochTime = d.getTime() / 1000;
      if (!isNaN(epochTime) && parseInt(epochTime)) {
        onChange(epochTime);
      }
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Pilih tanggal"
        value={value}
        sx={{ color: "#fff" }}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField size="small" sx={{ color: "#fff" }} {...params} />
        )}
      />
    </LocalizationProvider>
  );
}
