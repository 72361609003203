import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios'
import dayjs from 'dayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import Cookies from "universal-cookie";

const cookies = new Cookies()

export default function CreateTask({ open, onClose, global, onCreated }) {
    const [admins, setAdmins] = useState([])
    const [type, setType] = useState(null)
    const [types, setTypes] = useState([])
    const [name, setName] = useState('')
    const [deadline, setDeadline] = useState(null)
    const [note, setNote] = useState('')
    const [adminUids, setAdminUids] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const i = [...types].find(x => x.id === type)
        if(i && name.trim().length < 1) {
            setName(i.name)
        }
    }, [type])

    const fetchTaskTypes = () => {
        const url = `${global.api.base}/main/task-types`
        axios.get(url).then(({ data }) => {
            if (data.error) {
                console.error(data.msg)
            } else {
                setTypes(data.data)
            }
        }).catch(err => console.error(err.message))
    }

    const fetchAdmins = () => {
        const url = `${global.api.base}/main/admin-list`
        const data = { session: cookies.get('session') }
        axios.post(url, data).then(({ data }) => {
            if (data.error) {
                console.error(data.msg || data.message)
            } else {
                setAdmins(data.data)
            }
        }).catch(err => console.error(err.message || err))
    }
    
    const create = () => {
        setLoading(true)
        const url = `${global.api.base}/main/create-admin-task`
        const data = {
            name: name.trim(),
            type,
            deadline: deadline ? deadline.unix() : null,
            note,
            adminUids
        }
        axios.post(url, data)
            .then(({data}) => {
                if(data.error) {
                    console.error(data.msg)
                } else {
                    onCreated()
                    onClose()
                }
            })
            .catch(err => console.error(err.message))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (open) {
            fetchTaskTypes()
            fetchAdmins()
        } else {
            setAdmins([])
            setTypes([])
            setType(null)
            setAdminUids([])
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Buat Tugas</DialogTitle>
            <DialogContent
                sx={{
                    width: '350px'
                }}
                className="py-4"
            >
                <div className="flex flex-col gap-4">
                    <FormControl
                        fullWidth
                        size='small'
                        error={type === null}
                    >
                        <InputLabel id='task-type-label' className="bg-white px-2">Tipe Tugas</InputLabel>
                        <Select
                            labelId="task-type-label"
                            id='task-type'
                            value={type}
                            onChange={e => {
                                setType(e.target.value)
                            }}
                        >
                            {types.map((type, i) => (
                                <MenuItem value={type.id}>{type.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label='Nama Penugasan'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        size='small'
                        fullWidth
                        helperText={name.trim().length < 1 ? 'Nama terlalu pendek' : undefined}
                        error={name.trim().length < 1}
                    />
                    {/* Deadline */}
                    <div>
                        <div className="text-gray-500 text-xs">Atur Deadline</div>
                        <div className="flex items-center gap-4 mt-2">
                            <Checkbox
                                checked={deadline !== null}
                                onChange={e => {
                                    if (deadline === null) {
                                        setDeadline(dayjs().add(1, 'day'))
                                    } else {
                                        setDeadline(null)
                                    }
                                }}
                            />
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                            >
                                <DateTimePicker
                                    label='Deadline'
                                    disabled={deadline === null}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    slotProps={{
                                        textField: { size: 'small', disabled: deadline === null }
                                    }}
                                    value={deadline}
                                    onChange={e => {
                                        setDeadline(e)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    {/* Catatan */}
                    <TextField
                        size='small'
                        label='Catatan'
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        minRows={3}
                        maxRows={5}
                        multiline
                    />
                    {/* Admins */}
                    <div className="flex flex-col">
                        {admins.map((admin, i) => (
                            <div className="admin flex items-center p-0 w-full hover:bg-gray-300 cursor-pointer" key={i}
                                onClick={() => {
                                    let x = [...adminUids]
                                    if (x.includes(admin.uid)) {
                                        x = x.filter(x => x !== admin.uid)
                                    } else {
                                        x.push(admin.uid)
                                    }
                                    setAdminUids(x)
                                }}
                            >
                                <Checkbox
                                    checked={adminUids.includes(admin.uid)}
                                    size='small'
                                />
                                <div className="name text-gray-600">{admin.name}</div>
                            </div>
                        ))}
                    </div>
                    {adminUids.length < 1 && (
                        <Alert severity="warning">Pilih admin setidaknya 1</Alert>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="" variant='contained'
                    disabled={loading || name.trim().length < 1 || type === null || adminUids.length < 1}
                    onClick={create}
                >Buat</Button>
            </DialogActions>
        </Dialog>
    )
}