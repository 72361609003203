import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import global from "../../Global";
import axios from "axios";
import Cookies from "universal-cookie";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment-timezone";
import "moment/locale/id";
import RegardBonus from "./RegardBonus";
import smallCoin from "../../Static/Images/smallCoin.gif";

const cookies = new Cookies();
export default function Actions({
  ai,
  onChange,
  onEdit,
  bonus_claimed,
  bonus_estimate,
  invoice,
}) {
  function handleDelete() {
    if (!window.confirm("Anda yakin ingin menghapus data pembukuan ?")) {
      return;
    }
    setLoading(true);
    axios({
      method: "POST",
      data: {
        ai,
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/delete-data-pembukuan",
    })
      .then((response) => {
        const current_epoch = moment().tz("Asia/Jakarta").unix();
        onChange(current_epoch);
        if (response.data.error) {
          window.alert(response.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        window.alert(err.message);
        setLoading(false);
      });
  }

  const [editOpen, setEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [namaPembeli, setNamaPembeli] = useState("");
  const [namaProduk, setNamaProduk] = useState("");
  const [namaToko, setNamaToko] = useState("");
  const [hargaModal, setHargaModal] = useState("");
  const [hargaJual, setHargaJual] = useState("");

  const handleHargaJualChange = (e) => {
    const str = formatRupiah(onlyNumber(e.target.value), "Rp ");
    setHargaJual(str);
  };
  const handleHargaModalChange = (e) => {
    const str = formatRupiah(onlyNumber(e.target.value), "Rp ");
    setHargaModal(str);
  };

  useEffect(() => {
    try {
      setHargaJual(formatRupiah(onlyNumber(hargaJual), "Rp "));
      setHargaModal(formatRupiah(onlyNumber(hargaModal), "Rp "));
    } catch (err) {}
  }, [hargaJual, hargaModal]);

  const handleEditClose = () => setEditOpen(false);
  const handleEditOpen = () => setEditOpen(true);

  const [bonusClaimed, setBonusClaimed] = useState(bonus_claimed);
  const [regardBonusOpen, setRegardsBonusOpen] = useState(false);
  const [regardBonus, setRegardBonus] = useState(bonus_estimate);
  const handleRegardBonusClose = () => setRegardsBonusOpen(false);

  function handleClaimBonus() {
    setLoading(true);
    return axios({
      method: "POST",
      url: global.api.base + "/main/claim-bonus",
      data: {
        invoice,
        ai,
        session: cookies.get("session"),
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          setBonusClaimed(1);
          setRegardBonus(res.bonus);
          setRegardsBonusOpen(true);
        }
      })
      .catch((err) => {
        window.alert(err.message);
        setLoading(false);
      });
  }

  return (
    <div className="row-options">
      <RegardBonus
        open={regardBonusOpen}
        onClose={handleRegardBonusClose}
        bonus={regardBonus}
      />
      {!loading && (
        <>
          <Tooltip title="Edit">
            <IconButton onClick={onEdit}>
              <EditIcon size={"small"} className={"edit"} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={handleDelete} size="small" className="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {bonusClaimed == 0 && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <Tooltip title="Klaim bonus">
              <img
                onClick={handleClaimBonus}
                src={smallCoin}
                style={{
                  width: "20px",
                  height: "20px",
                  alignItems: "center",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              />
            </Tooltip>
          )}
        </>
      )}
      {loading && <LinearProgress size="20px" />}
      <Dialog open={editOpen} onClose={loading ? () => {} : handleEditClose}>
        {loading && <LinearProgress />}
        <DialogTitle>Edit pembukuan</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "3px 5px",
            }}
          >
            <TextField
              value={namaPembeli}
              onChange={(e) => setNamaPembeli(e.target.value)}
              disabled={loading}
              size="small"
              label="Nama Pembeli"
            />
            <TextField
              value={namaProduk}
              onChange={(e) => setNamaProduk(e.target.value)}
              disabled={loading}
              size="small"
              label="Nama Produk"
            />
            <TextField
              value={namaToko}
              onChange={(e) => setNamaToko(e.target.value)}
              disabled={loading}
              size="small"
              label="Nama Toko"
            />
            <TextField
              value={hargaModal}
              onChange={handleHargaModalChange.bind(this)}
              disabled={loading}
              size="small"
              label="Harga Modal"
            />
            <TextField
              value={hargaJual}
              onChange={handleHargaJualChange.bind(this)}
              disabled={loading}
              size="small"
              label="Harga Jual"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} disabled={loading}>
            Batal
          </Button>
          <Button disabled={loading}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function formatRupiah(angka, prefix) {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.match(/\d/g).join("");
}
