import { Dialog, DialogTitle } from "@mui/material";

export default function TimelineDialog({open, onClose}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Timeline</DialogTitle>
        </Dialog>
    )
}