import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Auth from "./Auth";
import Global from "./Global";
import Dashboard from "./Dashboard";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Landing from "./Landing";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Landing />
    <Auth global={Global} />
    <Dashboard global={Global} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
