import CoinImage from "../../Static/Images/coin.gif";
import Button from "@mui/material/Button";
import { useEffect } from "react";

export default function RegardBonus({ open, onClose, bonus }) {
  useEffect(() => {
    var timeout;

    if (open) {
      timeout = setTimeout(() => {
        onClose();
      }, 1000);
    } else {
      try {
        clearTimeout(timeout);
        timeout = undefined;
      } catch (err) {}
    }

    return () => {
      clearTimeout(timeout);
      timeout = undefined;
    };
  }, [open]);

  return (
    open && (
      <>
        <div
          className="regard-bonus-wrapper"
          style={{
            position: "fixed",
            overflow: "hidden",
            height: "100vh",
            maxHeight: "100vh",
            bottom: 0,
            left: 0,
            background: "transparent !important",
          }}
        ></div>
        <div
          className="regard-bonus"
          style={{
            zIndex: 9999999999,
            overflow: "hidden",
          }}
        >
          <img
            className="coin-animation"
            alt="Coin animation"
            src={CoinImage}
          />
          <div className="title">Selamat ...</div>
          <span
            className="subtitle"
            style={{ maxWidth: "200px", overflow: "visible" }}
          >
            Bonus sebanyak <b>{bonus.bonus.string_value}</b> (
            {bonus.bonus.percentage}%) dari profit penjualan ini telah
            ditambahkan
          </span>
          <Button
            sx={{
              background: "#FDD503 !important",
              color: "#FFFDEB",
              display: "flex",
              margin: "auto",
            }}
            onClick={onClose}
          >
            Tutup
          </Button>
        </div>
      </>
    )
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
