export default function RangeToggle({ value, onChange, opts, ...props }) {
  return (
    <div className="range-toggle" {...props}>
      {opts.map((opt, ind) => (
        <div
          key={ind}
          className={`opt${value == opt ? " active" : ""}`}
          onClick={() => onChange(opt)}
        >
          {opt}
        </div>
      ))}
    </div>
  );
}
