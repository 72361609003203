import { Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";

export default function DataRange({ onRangeChange }) {
  const [selected, setSelected] = useState(ranges[0].value);

  useEffect(() => {
    onRangeChange(selected);
  }, [selected]);

  return (
    <Tabs
      className="data-range"
      value={selected}
      size="small"
      onChange={(e, nb) => setSelected(nb)}
      sx={{ margin: "auto" }}
    >
      {ranges.map((range, index) => (
        <Tab value={range.value} label={range.label} key={index} />
      ))}
    </Tabs>
  );
}

const ranges = [
  {
    value: "DAILY",
    label: "Tanggal",
  },
  {
    value: "MONTHLY",
    label: "Bulan",
  },
];
