import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  ListItem,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  ListItemText,
} from "@mui/material";

const cookies = new Cookies();

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

var colors = ["#32AFA9", "#A4D4AE", "#E7F0C3", "#F0CE84"];

console.log(shuffle(colors));

export default function Admins({
  global,
  onAdmins,
  onSelectedAdmins,
  ...props
}) {
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    onAdmins(admins);
    onSelectedAdmins(selected);
  }, [admins, selected]);

  function loadAdmin() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/admin-list",
    }).then((response) => {
      setLoading(false);
      const res = response.data;
      if (res.error) {
        window.alert(res.message);
      } else {
        var i = [];
        for (var j = 0; j < res.data.length; j++) {
          i.push(res.data[j]);
          i[j].background = shuffle(colors)[0];
        }
        setAdmins(res.data);
        setSelected(res.data);
      }
    });
  }

  useEffect(() => {
    loadAdmin();
  }, []);

  function handleActiveToggle(admin) {
    var data = [...selected];
    if (data.indexOf(admin) >= 0) {
      data.splice(data.indexOf(admin), 1);
    } else {
      data.push(admin);
    }
    setSelected(data);
  }

  return (
    <Card {...props}>
      {loading && (
        <CircularProgress
          sx={{ width: "20px", marginTop: "10px", marginLeft: "10px" }}
          size="20px"
        />
      )}
      <CardContent>
        <List disablePadding={true} size="small" className="admin-list">
          {admins.map((admin, index) => (
            <ListItem
              disablePadding={true}
              size="small"
              key={index}
              className={`list ${selected.indexOf(admin) >= 0 ? "active" : ""}`}
              style={{
                color: "#000",
                background:
                  selected.indexOf(admin) >= 0 ? admin.background : "#fff",
              }}
            >
              <ListItemButton
                onClick={() => handleActiveToggle(admin)}
                sx={{ padding: 0 }}
              >
                <ListItemAvatar>
                  <Av
                    avatar={admin.avatar}
                    uid={admin.uid}
                    name={admin.name}
                    global={global}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span style={{ fontSize: "13px" }}>{admin.name}</span>
                  }
                  secondary={
                    <span style={{ fontSize: "10px", marginTop: "-10px" }}>
                      {admin.email}
                    </span>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

function Av({ uid, avatar, name, global }) {
  return (
    <Avatar
      size="small"
      sx={{ width: "30px", height: "30px", paddingRight: "-40px" }}
      src={
        avatar
          ? global.api.base + "/avatar/" + uid
          : `https://ui-avatars.com/api/?name=${name}&background=random`
      }
    />
  );
}
