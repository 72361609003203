import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Global from "../Global";
import "./index.scss";
import Dashboard from "./Dashboard";
import Sidebar from "./Layout/Sidebar";
import Header from "./Layout/Header";
import { useEffect, useState } from "react";
import Admin from "./Admin";
import Cookies from "universal-cookie";
import axios from "axios";
import Profile from "./Profile";
import PembukuanHarian from "./PembukuanHarian";
import Akun from "./Akun";
import UbahHargaPembukuan from "./UbahHargaPembukuan";
import BackupDatabase from "./BackupDatabase";
import Order from "./Order";
import PerformanceAccount from "./AccountPerformance";
import PembukuanBulanan from "./PembukuanBulanan";
import OrderCancelation from "./OrderCancelation";
import LaporanHarian from "./LaporanHarian";
import ShareProfit from "./ShareProfit";
import GraphIncome from "./GraphIncome";
import MonitorPendingOrder from "./MonitorPendingOrder";
import ManageAdminTask from "./ManageAdminTask";
import MyTask from './MyTask'

const cookies = new Cookies();
export default function Index({ global }) {
  function validateSession(session, errCallback) {
    axios({
      method: "POST",
      url: global.api.base + "/auth/validate-session",
      data: {
        session,
      },
    })
      .catch((err) => {
        errCallback();
      })
      .then((response) => {
        if (response.status !== 200) {
          errCallback();
        }
      });
  }

  const [navOpen, setNavOpen] = useState(false);

  const handleNavClose = () => {
    setNavOpen(false);
  };

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    if (!cookies.get("session")) {
      if (window.location.pathname.includes("/dashboard")) {
        window.location.href = "/auth";
      }
    } else {
      validateSession(cookies.get("session"), () => {
        if (window.location.pathname.includes("/dashboard")) {
          window.location.href = "/auth";
        }
      });
    }
  }, []);

  return (
    <Router basename="/dashboard">
      <div className="dashboard_main">
        <Header
          global={Global}
          onNavClose={handleNavClose.bind(this)}
          onNavToggle={handleNavToggle.bind(this)}
        />
        <Sidebar
          navOpen={navOpen}
          global={Global}
          onNavToggle={handleNavToggle.bind(this)}
          onNavClose={handleNavClose.bind(this)}
        />
        <div
          className="content"
          style={{
            width: navOpen ? "" : "100vw",
            transition: "0.2s ease-in-out all",
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard global={Global} />} />
            <Route path="/admin" element={<Admin global={Global} />} />
            <Route path="/profile" element={<Profile global={Global} />} />
            <Route
              path="/monitor-pending-order"
              element={<MonitorPendingOrder global={Global} />}
            />
            <Route
              path="/pembukuan-harian"
              element={<PembukuanHarian global={Global} />}
            />
            <Route path="/akun" element={<Akun global={Global} />} />
            <Route
              path="/ubah-harga-pembukuan"
              element={<UbahHargaPembukuan global={Global} />}
            />
            <Route
              path="/backup-database"
              element={<BackupDatabase global={global} />}
            />
            <Route path="/order" element={<Order global={global} />} />
            <Route
              path="/account-performance"
              element={<PerformanceAccount global={global} />}
            />
            <Route
              path="/pembukuan-bulanan"
              element={<PembukuanBulanan global={global} />}
            />
            <Route
              path="/order-cancelation"
              element={<OrderCancelation global={global} />}
            />
            <Route
              path="/laporan-harian"
              element={<LaporanHarian global={global} />}
            />
            <Route
              path="/share-profit"
              element={<ShareProfit global={global} />}
            />
            <Route
              path="/graph-income"
              element={<GraphIncome global={global} />}
            />
            <Route path='/manage-admin-task' 
              element={<ManageAdminTask global={global} />}
            />
            <Route path={'/my-task'} element={<MyTask global={global} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
