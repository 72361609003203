import {
  FormControl,
  TextField,
  Button,
  IconButton,
  LinearProgress,
} from "@mui/material";
import moment from "moment-timezone";
import "moment/locale/id";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default function ReportForm({ global, onReported }) {
  const [epoch, setEpoch] = useState(0);
  const [loading, setLoading] = useState(false);
  const [profit, setProfit] = useState(0);
  const [modal, setModal] = useState(0);
  const [order, setOrder] = useState(0);

  function getDailyReportData() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/daily-report-today",
    }).then((response) => {
      const res = response.data;
      if (res.error) {
        window.alert(
          `Gagal mengirim data laporan: ${res.message}, Silahkan refresh untuk mencoba lagi, jika hal ini terjadi terus menerus silahkan laporkan untuk mendapatkan bantuan.`
        );
      } else {
        setLoading(false);
        setProfit(res.profit);
        setModal(res.modal);
        setOrder(res.order);
      }
    });
  }

  useEffect(() => {
    const c = setInterval(
      () => setEpoch(moment().tz("Asia/Jakarta").unix()),
      1000
    );
    getDailyReportData();
    return () => {
      clearInterval(c);
    };
  }, []);

  function handleReport() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/lapor-statistik-harian",
    })
      .then((response) => {
        const res = response.data;
        setLoading(false);
        if (res.error) {
          window.alert(res.message);
        } else {
          onReported();
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(`Gagal mengirim data laporan: ${err.message}`);
      });
  }

  return (
    <>
      {loading && <LinearProgress sx={{ top: 0, left: 0, mb: 1 }} />}
      <div className="report-form">
        <div className="date" style={{ fontSize: "13px" }}>
          {moment(epoch * 1000).format("dddd, DD/MM/YYYY")}
        </div>
        <FormControl fullWidth sx={{ gap: "10px", mt: 1 }}>
          <TextField
            label="Profit"
            value={`${profit < 0 ? "- " : ""}${formatRupiah(profit, "Rp ")}`}
            size="small"
            disabled={true}
          />
          <TextField
            value={`${modal < 0 ? "- " : ""}${formatRupiah(modal, "Rp ")}`}
            label="Modal"
            size="small"
            disabled={true}
          />
          <TextField
            label="Order selesai"
            size="small"
            value={order}
            disabled={true}
          />
          <Button
            disabled={loading}
            size="small"
            sx={{
              width: "fit-content",
              display: "flex",
              marginLeft: "auto",
              marginRight: 0,
            }}
            variant="contained"
            onClick={handleReport}
          >
            Laporkan
          </Button>
        </FormControl>
      </div>
    </>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
