import {
    Card,
    CardContent,
    Button,
    TextField,
    IconButton,
    CircularProgress,
    Tooltip
} from "@mui/material";
import axios from "axios";
import {useEffect, useState} from "react";
import "./BackupDatabase.scss";
import DownloadIcon from "@mui/icons-material/Download";
import Cookies from "universal-cookie";

import StorageIcon from '@mui/icons-material/Storage';
import GridOnIcon from '@mui/icons-material/GridOn';

const cookies = new Cookies();
export default function BackupDatabase({global}) {
    const [downloadUrl, setDownloadUrl] = useState(undefined);
    const [loading, setLoading] = useState(false);

    function handleBackup() {
        setLoading(true);
        axios({
            method: "POST",
            url: global.api.base + "/main/backup-database",
            data: {
                session: cookies.get("session"),
            },
        })
            .then((response) => {
                setLoading(false);
                const res = response.data;
                if (res.error) {
                    window.alert(res.message);
                } else {
                    setDownloadUrl(res.url);
                }
            })
            .catch((err) => {
                setLoading(false);
                window.alert(err.message);
            });
    }

    function handleDownload() {
        return window.open(global.api.base + downloadUrl, "_blank");
    }

    function handleBackupXlsx() {
      setLoading(true)
      axios({
        method: "POST",
        data: {
          session: cookies.get('session')
        },
        url: global.api.base+'/main/backup-database-xlsx'
      }).then(response => {
        const res = response.data
        window.alert(res.message)
        setLoading(false)
      }).catch(err => {
        window.alert(err.message)
        setLoading(false)
      })
    }

    return (
        <Card className="backup-database">
            <CardContent>
                <div className="container">
                    {loading && (
                        <div className="loading">
                            <CircularProgress className="bar"/>
                            <div className="loading-title">Silahkan tunggu ...</div>
                        </div>
                    )}

                    {!loading && (
                        <>
                            {!downloadUrl && (
                                <div className={'flex-main-button'}>
                                    <Button
                                        startIcon={<StorageIcon/>}
                                        onClick={handleBackup}
                                        sx={{width: "fit-content", margin: "auto", display: "flex"}}
                                        variant="contained"
                                        size={'small'}
                                    >
                                        Backup Database (.sql)
                                    </Button>
                                    <Tooltip title={'Dikirim melalui telegram'}>
                                        <Button
                                            startIcon={<GridOnIcon />}
                                            size={'small'}
                                            onClick={handleBackupXlsx}
                                            sx={{width: "fit-content", margin: "auto", display: "flex"}}
                                            variant="contained"
                                        >
                                            Backup (.xlsx, .sql)
                                        </Button>
                                    </Tooltip>
                                </div>
                            )}
                            {downloadUrl && (
                                <div className="download">
                                    <Button
                                        startIcon={<DownloadIcon/>}
                                        variant="contained"
                                        onClick={handleDownload}
                                    >
                                        Download Backup (.sql)
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </CardContent>
        </Card>
    );
}
