import BackgroundImage from "../Static/Images/mainBgWrapper.jpg";

export default function Wrapper({ ...props }) {
  return (
    <div {...props}>
      <div
        className="bg-wrapper"
        style={{
          backgroundImage: `url('${BackgroundImage}')`,
          maxHeight:
            window.location.pathname === "/dashboard"
              ? "calc(100vh - 40px) !important"
              : "",
          overflow: window.location.pathname === "/dashboard" ? "hidden" : "",
          minHeight:
            window.location.pathname === "/dashboard"
              ? "calc(100vh - 40px)"
              : "",
        }}
      ></div>
      <div
        className="bg-wrapper-dark"
        style={{
          maxHeight:
            window.location.pathname === "/dashboard"
              ? "calc(100vh - 40px)"
              : "",
          overflow: window.location.pathname === "/dashboard" ? "hidden" : "",
        }}
      ></div>
    </div>
  );
}
