import noDataImage from "../../Static/Images/noData.png";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";

export default function NothingFound({ onAdd }) {
  return (
    <div className="nothing-found">
      <img
        className="nothing-image"
        src={noDataImage}
        alt="No data image"
        title="No data image"
      />
      <div className="title">Tidak ada pengaturan mengenai share profit.</div>
      <Tooltip title="Tambahkan pengaturan baru">
        <IconButton
          onClick={onAdd}
          sx={{
            width: "fit-content",
            background: "#1976D2 !important",
            display: "flex",
            margin: "auto",
            mt: 1.5,
          }}
        >
          <AddIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
    </div>
  );
}
