import {
  Dialog,
  DialogContent,
  DialogActions,
  LinearProgress,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardHeaderContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteIcon from "@mui/icons-material/Close";
import MovingIcon from "@mui/icons-material/Moving";

const cookies = new Cookies();
export default function DataDialog({ global, open, onClose, ai, onChange }) {
  const [market, setMarket] = useState("");
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blockSave, setBlockSave] = useState(true);
  const [pembulatan, setPembulatan] = useState(1);

  function loadData(ai) {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        ai,
      },
      url: global.api.base + "/main/load-share-profit",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        const data = res.data;
        setMarket(data.market);
        setLists(JSON.parse(data.rumus));
        setPembulatan(data.pembulatan);
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    if (open) {
      if (ai === null) {
        setMarket("");
        setLists([]);
        setLoading(false);
        setBlockSave(true);
        setPembulatan(1);
      } else {
        loadData(ai);
      }
    }
  }, [open]);

  useEffect(() => {
    setBlockSave(market.trim().length < 1 || !parseInt(pembulatan));
  }, [market, pembulatan]);

  useEffect(() => {
    setMarket(market.toLowerCase());
  }, [market]);

  const handleAddList = (list) => {
    const res = [...lists];
    if (res.indexOf(list) < 0) {
      res.push(list);
    }
    setLists(res);
  };

  function handleDeleteList(index) {
    const res = [...lists];
    res.splice(index, 1);
    setLists(res);
  }

  function handleSave() {
    const data = {
      session: cookies.get("session"),
      market,
      rumus: lists,
      ai,
      pembulatan: parseInt(pembulatan),
    };
    setLoading(true);
    axios({
      method: "POST",
      data,
      url: global.api.base + "/main/save-share-profit",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          onChange();
          onClose();
        }
      })
      .catch((err) => {
        window.alert(err.message);
        setLoading(false);
      });
  }

  function handleChangePembulatan(e) {
    setPembulatan(parseInt(e.target.value));
  }

  return (
    <Dialog open={open} onClose={loading ? () => {} : onClose}>
      {loading && <LinearProgress />}
      <DialogContent>
        <div className="data-dialog">
          <TextField
            disabled={loading}
            size="small"
            variant="standard"
            label="Market"
            helperText="ex: aliexpress, lazada, shopee"
            value={market}
            onChange={(e) => setMarket(e.target.value)}
          />
          <RumusAdd disabled={loading} onAdded={handleAddList} />
          <Lists
            lists={lists}
            onDeleteList={handleDeleteList}
            disabled={loading}
          />
          <TextField
            disabled={loading}
            size="small"
            variant="standard"
            type="number"
            label="Pembulatan"
            value={pembulatan}
            onChange={handleChangePembulatan}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          size="small"
          variant="contained"
          onClick={onClose}
        >
          Batal
        </Button>
        <Button
          disabled={loading || blockSave}
          size="small"
          variant="contained"
          onClick={handleSave}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Lists({ lists, onDeleteList, disabled }) {
  return (
    lists.length > 0 && (
      <List className="rumus-list-container">
        {lists.map((list, i) => (
          <ListItem key={i} className="list-item">
            <ListItemText sx={{ width: "fit-content", flex: "0 auto" }}>
              {formatRupiah(list.profit_range.from, "Rp ")}
            </ListItemText>
            <ListItemIcon sx={{ ml: 1, mr: 1, flex: "0", minWidth: "unset" }}>
              <CompareArrowsIcon sx={{ fontSize: "17px", color: "#DF8E00" }} />
            </ListItemIcon>
            <ListItemText sx={{ width: "fit-content", flex: "0 auto" }}>
              {formatRupiah(list.profit_range.to, "Rp ")}
            </ListItemText>
            <ListItemIcon sx={{ ml: 1, mr: 1, flex: "0", minWidth: "unset" }}>
              <MovingIcon sx={{ fontSize: "17px", color: "#DF8E00" }} />
            </ListItemIcon>
            <ListItemText>{list.increase}%</ListItemText>
            <IconButton
              disabled={disabled}
              onClick={onDeleteList.bind(this, i)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    )
  );
}

function RumusAdd({ onAdded, disabled }) {
  const [rangeFrom, setRangeFrom] = useState(0);
  const [rangeTo, setRangeTo] = useState(0);
  const [percentage, setPercentage] = useState("0");
  const [blockAdd, setBlockAdd] = useState(false);

  useEffect(() => {
    try {
      const p = parseFloat(percentage);
      if (isNaN(p)) {
        setBlockAdd(true);
      } else {
        setBlockAdd(false);
      }
    } catch (err) {
      setBlockAdd(true);
    }
  }, [percentage]);

  const handleChangeRangeFrom = (e) => {
    try {
      setRangeFrom(parseInt(onlyNumber(e.target.value)));
    } catch (err) {
      setRangeFrom(0);
    }
  };

  const handleChangeRangeTo = (e) => {
    try {
      setRangeTo(parseInt(onlyNumber(e.target.value)));
    } catch (err) {
      setRangeTo(0);
    }
  };

  return (
    <Card className="rumus-add">
      <CardContent>
        <div className="range-profit">
          <div className="title">Range profit</div>
          <div className="input">
            <TextField
              size="small"
              label="Dari"
              disabled={disabled}
              onChange={handleChangeRangeFrom}
              value={formatRupiah(rangeFrom, "Rp ")}
            />
            <TextField
              size="small"
              label="Ke"
              disabled={disabled}
              onChange={handleChangeRangeTo}
              value={formatRupiah(rangeTo, "Rp ")}
            />
          </div>
        </div>
        <div className="set-bonus">
          <TextField
            type="number"
            label="Persentase"
            size="small"
            sx={{ width: "100%" }}
            disabled={disabled}
            value={percentage}
            onChange={(e) => {
              try {
                setPercentage(e.target.value);
              } catch (err) {
                console.error(err);
              }
            }}
          />
        </div>
        <Button
          size="small"
          sx={{ mt: 1, display: "flex", marginLeft: "auto", marginRight: "0" }}
          variant="outlined"
          disabled={blockAdd || disabled}
          onClick={() => {
            onAdded({
              profit_range: {
                from: parseInt(rangeFrom.toFixed(0)),
                to: parseInt(rangeTo.toFixed(0)),
              },
              increase: parseFloat(percentage),
            });
          }}
        >
          Tambahkan
        </Button>
      </CardContent>
    </Card>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.match(/\d/g).join("");
}
