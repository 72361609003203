import { useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  CircularProgress,
  Tooltip,
  LinearProgress,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/FileDownloadTwoTone";
import axios from "axios";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import moment from "moment-timezone";
import "moment/locale/id";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as Tp,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import jSPDF from "jspdf";
import { useRef } from "react";
import jsPDF from "jspdf";
import { Pie } from "react-chartjs-2";
import CameraIcon from "@mui/icons-material/Camera";
import html2canvas from "html2canvas";
import domToImage from "dom-to-image";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Cookies from "universal-cookie";
import Global from "../../Global";

const cookies = new Cookies();
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tp,
  Legend,
  ArcElement
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "PROFIT PER MINGGU",
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const labels = ["Week 1", "Week 2", "Week 3", "Week 4"];

export default function Admin({ global, name, uid, datetime }) {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [epoch, setEpoch] = useState(0);

  useEffect(() => {
    setEpoch(moment(datetime.$d).unix());
  }, [datetime]);

  function LoadData() {
    console.log(moment(datetime).format("M"));
    setLoading(true);
    axios({
      method: "POST",
      data: {
        uid: uid,
        year: moment(epoch * 1000).format("YYYY"),
        month: moment(epoch * 1000).format("M"),
      },
      url: global.api.base + "/main/pembukuan-bulanan",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        setData(res);
        setLoaded(true);
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  return (
    <div className="admin">
      {loaded && (
        <Load epoch={epoch} global={global} name={name} uid={uid} data={data} />
      )}
      {!loaded && (
        <UnLoad
          loading={loading}
          global={global}
          name={name}
          uid={uid}
          onLoad={LoadData}
        />
      )}
    </div>
  );
}

function UnLoad({ onLoad, global, name, uid, loading }) {
  return (
    <Card>
      {loading && <LinearProgress />}
      <div className="head-name">{name}</div>
      <CardContent>
        <Tooltip title="Muat data">
          <IconButton disabled={loading} onClick={onLoad} className="load-data">
            <AutorenewIcon />
          </IconButton>
        </Tooltip>
      </CardContent>
    </Card>
  );
}

function resetShareProfit(uid) {
  axios({
    method: "POST",
    data: {
      admin: {
        uid,
      },
      session: cookies.get("session"),
    },
    url: Global.api.base + `/main/reset-sp-from-print`,
  })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        window.alert(`Server mengembalikan nilai tidak valid: ${res.message}`);
      }
    })
    .catch((err) => {
      window.alert(err.message);
    });
}

function Load({ global, name, uid, data, epoch }) {
  const [downloading, setDownloading] = useState(false);
  const reportRef = useRef(null);

  async function handleDownloadPdf() {
    setDownloading(true);
    domToImage.toPng(reportRef.current).then((dataUrl) => {
      const fakeLink = document.createElement("a");
      fakeLink.download = `${name} - ${moment(epoch * 1000).format(
        "MMMM YYYY"
      )}.png`;
      fakeLink.href = dataUrl;
      fakeLink.click();
      setDownloading(false);
      resetShareProfit(uid);
    });
  }

  function handleKirimEmail() {
    handleDownloadPdf();
    setDownloading(true);
    domToImage.toPng(reportRef.current).then((blob) => {
      const filename = `${name} - ${moment(epoch * 1000).format(
        "MMMM YYYY"
      )}.png`;
      const data = {
        session: cookies.get("session"),
        attachment: {
          name: filename,
          blob,
        },
        request: {
          timestamp: epoch,
        },
        _for: {
          admin: {
            uid: uid,
          },
        },
      };
      axios({
        method: "POST",
        data,
        url: global.api.base + "/main/lapor-email-bulanan",
      })
        .then((response) => {
          setDownloading(false);
          const res = response.data;
          window.alert(res.message);
        })
        .catch((err) => {
          setDownloading(false);
          window.alert(err.message);
        });
    });
  }

  return (
    <div>
      <Card className="main-data" ref={reportRef} sx={{ background: "#fff" }}>
        <div className="head-name">
          {name} - {moment(data.date * 1000).format("MMMM YYYY")}
        </div>
        {!downloading && (
          <>
            <Tooltip title="Download sebagai Gambar">
              <IconButton
                onClick={handleDownloadPdf}
                size="small"
                sx={{ position: "absolute", top: 0, right: "30px" }}
              >
                <CameraIcon size="15px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Kirim sebagai Email">
              <IconButton
                onClick={handleKirimEmail}
                size="small"
                sx={{ position: "absolute", top: 0, right: "5px" }}
              >
                <AlternateEmailIcon size="15px" />
              </IconButton>
            </Tooltip>
          </>
        )}
        <CardContent sx={{ background: "#fff" }}>
          <Laba data={data.laba} className="laba" date={data.date} />
          <ProfitMingguanChart
            data={data.profitMingguan}
            className="profit-mingguan-chart"
            sx={{ mt: 1 }}
          />
          <DetailHasilKerja
            data={data.hasilKerja}
            className="detail-hasil-kerja"
            shareProfit={data.laba.shareProfit}
          />
          <PenjualanGraph data={data.laba.markets} />
        </CardContent>
      </Card>
    </div>
  );
}

function getUniqueArrayLength(arrays) {
  var res = {};
  for (var arr of arrays) {
    if (typeof res[arr] == "undefined") {
      res[arr] = 1;
    } else {
      res[arr]++;
    }
  }
  return res;
}

function PenjualanGraph({ data, ...props }) {
  const graphData = getUniqueArrayLength(data);

  const d = {
    labels: Object.entries(graphData).map((x) => x[0]),
    datasets: [
      {
        label: "Sumber Penjualan",
        data: Object.entries(graphData).map((x) => x[1]),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    data.length > 0 && (
      <Card
        {...props}
        sx={{
          mt: 5,
          width: "80%",
          height: "80%",
          display: "flex",
          margin: "auto",
          flexDirection: "column",
          p: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "5px",
            marginLeft: "5px",
            fontSize: "12px",
            fontWeight: 400,
            textAlign: "center",
            mr: 1,
          }}
        >
          Sumber Penjualan
        </Typography>
        <Pie data={d} />
      </Card>
    )
  );
}

function DetailHasilKerja({ data, shareProfit, ...props }) {
  return (
    <Card {...props} sx={{ mt: 1 }}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: "5px",
          marginLeft: "5px",
          fontSize: "12px",
          fontWeight: 400,
          textAlign: "center",
          mr: 1,
        }}
      >
        HASIL KERJA
        <TableContainer sx={{ borderRadius: "4px" }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ background: "#9BC0E5" }}>
                  Produk Terjual
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ background: "#8EA9DC" }}
                  className="light-cell"
                >
                  {data.produkTerjual < 1 ? "-" : data.produkTerjual} Pcs
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ background: "#66A4D6" }}>
                  Profit per-Produk
                </TableCell>
                <TableCell
                  sx={{ background: "#6A8ED0" }}
                  align="right"
                  className="light-cell"
                >
                  {data.rataRataProfitPerProduct < 0
                    ? "- " + formatRupiah(data.rataRataProfitPerProduct)
                    : formatRupiah(data.rataRataProfitPerProduct)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ background: "#498DD1" }}>
                  Produk Terjual per-Hari
                </TableCell>
                <TableCell
                  sx={{ background: "#3D6CC0" }}
                  align="right"
                  className="light-cell"
                >
                  {data.rataRataPenjualanPerhari < 1
                    ? "-"
                    : data.rataRataPenjualanPerhari}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ background: "#2E74B3" }}>
                  Profit per-Hari
                </TableCell>
                <TableCell
                  sx={{ background: "#2F568F" }}
                  align="right"
                  className="light-cell"
                >
                  {data.rataRataProfitPerHari < 0
                    ? "- " + formatRupiah(data.rataRataProfitPerHari)
                    : formatRupiah(data.rataRataProfitPerHari)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ background: "#2F568F" }}>
                  Omset per-Hari
                </TableCell>
                <TableCell
                  sx={{ background: "#19498F" }}
                  align="right"
                  className="light-cell"
                >
                  {data.rataRataOmsetPerHari < 0 ? "- " : ""}
                  {formatRupiah(data.rataRataOmsetPerHari)}
                </TableCell>
              </TableRow>

              {data.gaji.map((gaji, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ backgroundColor: "#BCD7EC" }}>
                    {gaji.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#CFF69D",
                      fontWeight: "500",
                    }}
                    align="right"
                  >
                    {formatRupiah(gaji.value, "Rp ")}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={{
                    background: "#BCD7EC",
                  }}
                >
                  Share Profit
                </TableCell>
                <TableCell sx={{ background: "#CFF69D" }} align="right">
                  {formatRupiah(shareProfit)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    background: "#BCD7EC",
                    fontWeight: 600,
                  }}
                >
                  TOTAL YANG DITERIMA
                </TableCell>
                <TableCell
                  sx={{ background: "#CFF69D", fontWeight: 600 }}
                  align="right"
                >
                  {(() => {
                    var v = 0;
                    const gajis = data.gaji.map((gaji) => gaji.value);
                    for (var i = 0; i < gajis.length; i++) {
                      v += gajis[i];
                    }
                    v+=shareProfit
                    return formatRupiah(v);
                  })()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Typography>
    </Card>
  );
}

function ProfitMingguanChart({ data, ...props }) {
  const [bonus, setBonus] = useState(0);

  useEffect(() => {
    const b = data.map((x) => x.profit);
    var s = 0;
    for (var x of b) {
      s += x;
    }
    setBonus(parseInt((s / 100) * 3));
  }, []);

  return (
    <Card {...props}>
      <CardContent>
        <Line
          options={options}
          data={{
            labels,
            datasets: [
              {
                label: "PROFIT PER MINGGU",
                data: data.map((x) => x.profit),
                borderColor: "#00A2C3",
                backgroundColor: "#00C1F1",
                yAxisID: "y",
              },
            ],
          }}
        />
      </CardContent>
      {/* <Typography
        variant="h6"
        sx={{
          marginBottom: "5px",
          marginLeft: "5px",
          fontSize: "12px",
          fontWeight: 400,
        }}
      >
        Bonus: {bonus < 1 ? "- " : ""}
        {formatRupiah(bonus, "Rp ")}
      </Typography> */}
    </Card>
  );
}

function Laba({ date, data, ...props }) {
  return (
    <Paper {...props} sx={{ overflow: "hidden" }}>
      <TableContainer>
        <Table hover size="small" sx={{ overflow: "hidden" }}>
          <TableHead>
            <TableRow sx={{ background: "#074E0A", color: "#fff" }}>
              <TableCell
                sx={{ textAlign: "center", color: "#fff" }}
                colSpan={2}
              >
                LAPORAN LABA{" "}
                {moment(date * 1000)
                  .tz("Asia/Jakarta")
                  .format("MMMM YYYY")
                  .toUpperCase()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ background: "#C4DFB2" }}>
              <TableCell>OMSET</TableCell>
              <TableCell align="right">
                {data.omset < 0 ? "- " : ""}
                {formatRupiah(data.omset, "Rp ")}
              </TableCell>
            </TableRow>
            <TableRow sx={{ background: "#A7D08E" }}>
              <TableCell>MODAL</TableCell>
              <TableCell align="right">
                {data.modal < 0 ? "- " : ""}
                {formatRupiah(data.modal, "Rp ")}
              </TableCell>
            </TableRow>
            <TableRow sx={{ background: "#74B24A" }}>
              <TableCell>PROFIT</TableCell>
              <TableCell align="right">
                {data.bruto < 0 ? "- " : ""}
                {formatRupiah(data.bruto, "Rp ")}
              </TableCell>
            </TableRow>
            <TableRow sx={{ background: "#60943D" }}>
              <TableCell>GIFT</TableCell>
              <TableCell align="right">
                {data.gift < 0 ? "- " : ""}
                {formatRupiah(data.gift, "Rp ")}
              </TableCell>
            </TableRow>
            <TableRow sx={{ background: "#60943D" }}>
              <TableCell>BIAYA KEHILANGAN</TableCell>
              <TableCell align="right">
                {data.finalProfit < 0 ? "- " : ""}
                {formatRupiah(data.lostMoney, "Rp ")}
              </TableCell>
            </TableRow>
            <TableRow sx={{ background: "#4d7b2f" }} className="text-white">
              <TableCell>FINAL PROFIT</TableCell>
              <TableCell align="right">
                {data.finalProfit < 0 ? "- " : ""}
                {formatRupiah(data.finalProfit, "Rp ")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
