import { TextField, Button, Link, LinearProgress, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ForgotPassword({ global }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [notifOpen, setNotifOpen] = useState(false);

  const navigate = useNavigate();

  // ! Event handler
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleNext = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/auth/forgot-password",
      data: { email },
    })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setNotifOpen(true);
        setErrorState(response.data.error);
        setErrorMessage(response.data.message);
        if (!response.data.error) {
          setEmail("");
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifOpen(true);
        setErrorState(true);
        setErrorMessage(err.code);
      });
  };

  useEffect(() => {
    document.title = `Forgot Password - ${global.appname}`;
  }, []);

  return (
    <div className="forgot-password form">
      {loading && (
        <LinearProgress
          sx={{ width: "100%", top: 0, left: 0, position: "absolute" }}
        />
      )}
      <img src={global.icon} className="icon" alt={global.appname} />
      <div className="header">
        <div className="title">Forgot password</div>
        <div className="subtitle">
          Silahkan masukkan email anda untuk melanjutkan
        </div>
      </div>
      {notifOpen && (
        <Alert severity={errorState ? "error" : "success"} sx={{ mt: 1 }}>
          {errorMessage}
        </Alert>
      )}
      <div className="body">
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Email"
          type="email"
          autoFocus
          disabled={loading}
          value={email}
          onChange={handleEmailChange.bind(this)}
        />
        <div className="actions">
          <Link
            component="button"
            className="left"
            variant="body2"
            underline="hover"
            onClick={() => {
              navigate("/login");
            }}
          >
            Ingat password
          </Link>
          <Button
            className="right"
            size="small"
            variant="contained"
            disabled={loading}
            onClick={handleNext.bind(this)}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}
