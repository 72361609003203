import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import "moment/locale/id";
import RowOption from "./RowOption";
import { useEffect } from "react";

export default function AdminTable({ data, onRefresh }) {
  const rows = [];
  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div className="main-admin-profile">
          <div className="name">{row.name}</div>
          <div className="uid">{row.uid}</div>
        </div>
      ),
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "IP",
      selector: (row) => row.ip,
      sortable: false,
    },
    {
      name: "Terdaftar sejak",
      sortable: false,
      selector: (row) => (
        <div className="data_registered_since">
          <div className="date">
            {moment(row.created * 1000).format("dddd, DD-MM-YYYY")}
          </div>
          <div className="from-now">{moment(row.created * 1000).fromNow()}</div>
        </div>
      ),
    },
    {
      name: "Terakhir login",
      sortable: false,
      selector: (row) => (
        <div className="data_registered_since">
          <div className="date">
            {moment(row.last_login * 1000).format("dddd, DD-MM-YYYY")}
          </div>
          <div className="from-now">
            {moment(row.last_login * 1000).fromNow()}
          </div>
        </div>
      ),
    },
    {
      name: "",
      sortable: false,
      selector: (row) => <RowOption onRefresh={onRefresh} uid={row.uid} />,
    },
  ];

  return (
    <DataTable
      className="admin-table"
      columns={columns}
      data={data}
      pagination
      sx={{
        overflowX: "scroll",
      }}
    />
  );
}
