import {
  Card,
  TextField,
  LinearProgress,
  Button,
  IconButton,
  CardContent,
  CardActionArea,
} from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import "./UbahHargaPembukuan.scss";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default function UbahHargaPembukuan({ global }) {
  const [pid, setPid] = useState("");
  const [hargaModal, setHargaModal] = useState("");
  const [hargaJual, setHargaJual] = useState("");

  useEffect(() => {
    try {
      setHargaJual(formatRupiah(onlyNumber(hargaJual), "Rp "));
    } catch (e) {}
    try {
      setHargaModal(formatRupiah(onlyNumber(hargaModal), "Rp "));
    } catch (e) {}
  }, [hargaJual, hargaModal]);

  const [loading, setLoading] = useState(false);

  function handleUbah() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        pid,
        hargaJual,
        hargaModal,
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/update-harga-pembukuan",
    })
      .then((response) => {
        setLoading(false);
        window.alert(response.data.message);
        if (!response.data.error) {
          setHargaJual("");
          setHargaModal("");
          setPid("");
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  return (
    <Card className="ubah-harga-pembukuan">
      {loading && <LinearProgress />}
      <CardContent className="form">
        <TextField
          size="small"
          value={pid}
          onChange={(e) => setPid(e.target.value)}
          label="P-ID"
          type="number"
          required
          disabled={loading}
        />
        <TextField
          value={hargaJual}
          onChange={(e) => setHargaJual(e.target.value)}
          label="Harga Jual"
          size="small"
          required
          disabled={loading}
        />
        <TextField
          value={hargaModal}
          onChange={(e) => setHargaModal(e.target.value)}
          disabled={loading}
          label="Harga Modal"
          size="small"
          required
        />
        <Button
          onClick={handleUbah}
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={loading}
        >
          Simpan
        </Button>
      </CardContent>
    </Card>
  );
}

function formatRupiah(angka, prefix) {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.match(/\d/g).join("");
}
