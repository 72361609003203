import { useEffect, useState } from "react";
import axios from "axios";
import {
  IconButton,
  Button,
  TextField,
  Autocomplete,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  LinearProgress,
  Tooltip,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/AddTwoTone";
import Cookies from "universal-cookie";
import DynamicAddUrls from "../../Component/DynamicAddUrls";

const cookies = new Cookies();
export default function Add({ global, onChange }) {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  const getStores = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/stores",
    })
      .then((response) => {
        setLoading(false);
        setStores(response.data);
      })
      .catch(async (err) => {
        await new Promise((resolve) => setTimeout(resolve, 300));
        getStores();
      });
  };

  //   form data
  const [pembeli, setPembeli] = useState("");
  const [produk, setProduk] = useState(0);
  const [urlProduk, setUrlProduk] = useState([]);
  const [hargaJual, setHargaJual] = useState(0);
  const [hargaModal, setHargaModal] = useState(0);
  const [toko, setToko] = useState("");
  const [invoice, setInvoice] = useState("");

  useEffect(() => {
    if (open) {
      setPembeli("");
      setProduk(1);
      setUrlProduk([]);
      setHargaJual(0);
      setHargaModal(0);
      setToko("");
      setInvoice("");
    }
  }, [open]);

  //   end of form data
  const [blockAdd, setBlockAdd] = useState(false);

  const [profit, setProfit] = useState(0);

  useEffect(() => {
    setProfit(hargaJual - hargaModal);
  }, [hargaModal, hargaJual]);

  useEffect(() => {
    if (pembeli.length >= 1) {
      setPembeli(capitalizeWord(pembeli));
    }
  }, [pembeli]);

  useEffect(() => {
    if (pembeli.length < 3 || pembeli.length > 30) {
      setBlockAdd(true);
    } else if (produk < 1) {
      setBlockAdd(true);
    } else if (urlProduk.length < 1) {
      setBlockAdd(true);
    } else if (toko == null || toko.length < 2) {
      setBlockAdd(true);
    } else if (!validInvoice(invoice)) {
      setBlockAdd(true);
    } else {
      setBlockAdd(false);
    }
  }, [pembeli, produk, urlProduk, hargaJual, hargaModal, toko, invoice]);

  const handleChangeHargaModal = (e) => {
    try {
      setHargaModal(parseInt(onlyNumber(e.target.value)));
    } catch (err) {
      setHargaModal(0);
    }
  };

  const handleChangeHargaJual = (e) => {
    try {
      setHargaJual(parseInt(onlyNumber(e.target.value)));
    } catch (err) {
      setHargaJual(0);
    }
  };

  function handleAdd() {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/create-pending-order",
      data: {
        session: cookies.get("session"),
        pembeli,
        produk,
        urlProduk,
        hargaJual,
        hargaModal,
        toko,
        invoice,
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          onChange();
          onClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    getStores();
  }, []);

  return (
    <>
      {open && (
        <Dialog open={open} onClose={loading ? () => {} : onClose}>
          {loading && <LinearProgress />}
          <DialogContent className="add">
            <FormControl fullWidth className="forms">
              <TextField
                value={pembeli}
                onChange={(e) => setPembeli(e.target.value)}
                size="small"
                label="Pembeli"
                disabled={loading}
              />
              <TextField
                size="small"
                label="Pcs"
                disabled={loading}
                value={produk}
                onChange={(e) => {
                  try {
                    setProduk(parseInt(onlyNumber(e.target.value)));
                  } catch (err) {
                    setProduk(0);
                  }
                }}
                type="number"
              />
              <DynamicAddUrls
                urls={urlProduk}
                onChange={(urls) => {
                  setUrlProduk(urls);
                }}
              />
              <TextField
                size="small"
                onChange={handleChangeHargaModal}
                value={formatRupiah(hargaModal, "Rp ")}
                disabled={loading}
                label="Harga Modal"
              />
              <TextField
                size="small"
                onChange={handleChangeHargaJual}
                label="Harga Jual"
                value={formatRupiah(hargaJual, "Rp ")}
                disabled={loading}
                error={profit < 0}
                helperText={`Profit: ${profit < 0 ? " - " : ""}${formatRupiah(
                  profit,
                  "Rp "
                )}`}
              />
              <TextField
                size="small"
                label="Invoice"
                value={invoice}
                onChange={(e) => setInvoice(e.target.value)}
                disabled={loading}
              />
              <Autocomplete
                options={stores}
                clearOnBlur
                disabled={loading}
                value={toko}
                onChange={(e, n) => setToko(n)}
                clearOnEscape
                size="small"
                renderInput={(params) => <TextField label="Toko" {...params} />}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={onClose}>
              Batal
            </Button>
            <Button disabled={loading || blockAdd} onClick={handleAdd}>
              Tambahkan
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!open && (
        <Tooltip title="Tambahkan orderan baru">
          <IconButton onClick={() => setOpen(true)} className="floating-add">
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.match(/\d/g).join("");
}

function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

function capitalizeWord(str) {
  var s = [];
  for (var t of str.split(" ")) {
    try {
      const first = t[0].toUpperCase();
      const letter = t.slice(1);
      s.push(first + letter);
    } catch (e) {
      s.push("");
    }
  }
  return s.join(" ");
}

const validInvoice = (str) => {
  return /(INV\/(?:[0-9]){1,10}\/(?:[A-Z]{1,5})\/(?:[0-9]{1,15}))\w+/g.test(
    str
  );
};
