import { CircularProgress, IconButton, LinearProgress } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import DeleteIcon from "@mui/icons-material/Delete";
import global from "../../Global";
import { useEffect, useState } from "react";
import ActionCookies from './ActionCookies'
import CookiesIcon from '@mui/icons-material/Cookie'
const cookies = new Cookies();

export default function Action({ params, onDeleted }) {
  const [loading, setLoading] = useState(false);
  const [openUpdateCookies, setOpenUpdateCookies] = useState(false)

    const updateCookies = () => setOpenUpdateCookies(true)
    const closeUpdateCookies = () => setOpenUpdateCookies(false)

  function handleDelete() {
    if (window.confirm("Anda yakin ingin menghapus akun ini ?")) {
      setLoading(true);
      axios({
        method: "POST",
        data: {
          session: cookies.get("session"),
          ai: params.id,
        },
        url: global.api.base + "/main/delete-akun",
      })
        .then((response) => {
          setLoading(false);
          const res = response.data;
          if (res.error) {
            window.alert(res.message);
          } else {
            onDeleted(params.id);
          }
        })
        .catch((err) => {
          setLoading(false);
          window.alert(err.message);
        });
    }
  }

  return (
    <>
        <ActionCookies open={openUpdateCookies} onClose={closeUpdateCookies} {...params} />
      {!loading && (
        <IconButton
          onClick={() => {
            handleDelete();
          }}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      )}
      {loading && <CircularProgress size="20px" color="primary" />}
      <IconButton size='small' onClick={updateCookies}>
        <CookiesIcon />
      </IconButton>
    </>
  );
}
