import { useEffect, useState } from "react";
import { Card, IconButton, Avatar, Divider, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import Cookie from "universal-cookie";
import axios from "axios";
import Notification from "./Notification";
import PembukuanIcon from "@mui/icons-material/AutoStories";
import { Link } from "react-router-dom";
import Pendapatan from "./PendapatanSimple";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Gift from "./Gift";
import { useIdleTimer } from "react-idle-timer";
import Global from "../../Global";
import LostMoney from './LostMoney'

const cookie = new Cookie();
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  var s = "";
  try {
    s = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  } catch (err) {}

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: s,
  };
}

export default function Header({ global, onNavToggle }) {
  const [profileOpen, setProfileOpen] = useState(false);
  const [profile, setProfile] = useState({});

  const onPrompt = () => {
    // Fire a Modal Prompt
  };

  const onIdle = () => {
    // Close Modal Prompt
    handleSignout();
    // Do some idle action like log out your user
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 60 * 60000,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  useEffect(() => {
    const session = cookie.get("session");
    axios({
      method: "POST",
      url: global.api.base + "/main/profile-detail",
      data: {
        session,
      },
    }).then((response) => {
      setProfile(response.data.data);
    });
  }, []);

  function handleSignout() {
    try {
      cookie.remove("session");
    } catch (err) {}
    if (window.location.pathname.includes("/dashboard")) {
      window.location.href = "/auth";
    }
  }

  const navigate = useNavigate();

  function handleGotoProfile() {
    navigate("/profile");
  }

  return (
    <Card className="header">
      <div className="left">
        <IconButton onClick={onNavToggle}>
          <MenuIcon />
        </IconButton>
        <img
          src={global.icon}
          className="icon"
          alt={global.appname}
          title={global.appname}
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="app-name">{global.appname}</div>
      </div>
      <div className="right">
        <Pendapatan />
        <Tooltip title="Finish Order">
          <Link to="/pembukuan-harian" style={{ marginRight: "10px" }}>
            <PembukuanIcon sx={{ color: "#757575" }} />
          </Link>
        </Tooltip>
        <Tooltip title="New Order">
          <Link to="/order" sx={{ marginRight: "10px" }}>
            <ShoppingBagIcon sx={{ color: "#757575" }} />
          </Link>
        </Tooltip>
        <Gift global={global} />
        <LostMoney global={global} />
        <Notification global={global} />
        <Avatar
          className="avatar-icon"
          size="small"
          onClick={() => {
            setProfileOpen(!profileOpen);
          }}
          {...stringAvatar(
            typeof profile.name !== "undefined" ? profile.name : ""
          )}
          src={
            profile.avatar ? `${Global.api.base}/avatar/${profile.uid}` : "#"
          }
        />
      </div>
      {profileOpen && (
        <Card className="profile_options">
          <div className="profile">
            <Avatar
              className="avatar"
              size="small"
              {...stringAvatar(profile.name ? profile.name : "")}
              src={
                profile.avatar
                  ? `${Global.api.base}/avatar/${profile.uid}`
                  : "#"
              }
            />
            <div className="detail">
              <div className="name">{profile.name ? profile.name : "..."}</div>
              <div className="email">
                {profile.email ? profile.email : "...@..."}
              </div>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <div className="d-flex">
                <button
                  style={{ marginRight: "12px", fontSize: "13px" }}
                  onClick={handleGotoProfile}
                  className="signout"
                >
                  Profile
                </button>
                <button
                  style={{ fontSize: "13px" }}
                  onClick={handleSignout.bind(this)}
                  className="signout"
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Card>
  );
}
