import icon from "./Static/Images/logo.png";

export default {
  appname: "Pembukuan Knine",
  author: "Kampung Songo",
  icon: icon,
  api: {
    // base: "https://pembukuan.asistenq.com"
    // base: "https://pembukuan.rencangdahar.com",
    // base: "http://localhost:5000",
    base: "https://pembukuan.srv-ziqlabs-1.my.id"
  },
};
