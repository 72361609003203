import {
  TextField,
  IconButton,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/CloseSharp";

export default function DynamicAddUrls({ urls, onChange }) {
  function handleRemoveUrl(i) {
    var u = [...urls];
    u.splice(i, 1);
    onChange(u);
  }

  return (
    <Card
      className="dynamic-add-urls"
      sx={{ width: "100%", padding: 0, margin: 0 }}
    >
      <CardContent
        sx={{ display: "flex", gap: "10px", flexDirection: "column" }}
      >
        <Add
          onAdded={(url) => {
            var u = [...urls];
            if (u.indexOf(url) < 0) {
              u.push(url.trim());
            }
            onChange(u);
          }}
        />
        <List size="small" disablePadding>
          {urls.map((url, i) => (
            <ListItem disablePadding>
              <ListItemText secondary={url} />
              <IconButton onClick={handleRemoveUrl.bind(this, i)} size="small">
                <RemoveIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

function Add({ onAdded }) {
  const [url, setUrl] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(isValidUrl(url));
  }, [url]);

  function handleAdd() {
    onAdded(url);
    setUrl("");
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "start",
      }}
    >
      <TextField
        size="small"
        error={!valid}
        helperText={!valid ? "Invalid url" : ""}
        label="URL Produk"
        value={url}
        onChange={(e) => {
          setUrl(e.target.value);
        }}
      />
      <IconButton
        onClick={handleAdd}
        size="small"
        sx={{ mt: 0.5 }}
        disabled={!valid}
      >
        <AddIcon size="small" />
      </IconButton>
    </div>
  );
}

function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}
