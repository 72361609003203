import { useEffect, useState } from 'react'
import AddTaskTypeDialog from './AddTaskTypeDialog'
import './index.scss'
import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField, TableContainer, TablePagination, Alert, Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import { useDebounce } from 'use-debounce'
import axios from 'axios'
import Cookies from 'universal-cookie'
import CreateTask from './CreateTask'
import moment from 'moment-timezone'
import 'moment/locale/id'
import TimelineDialog from './TimelineDialog'
import CloseIcon from '@mui/icons-material/Close';
import TaskType from './TaskType'

const cookies = new Cookies()

export default function ManageAdminTask({ global }) {
    const [addTaskTypeOpen, setAddTaskTypeOpen] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch]  = useState('')
    const [count, setCount] = useState(0)
    const [createTaskOpen, setCreateTaskOpen] = useState(false)
    const [timelines, setTimelines] = useState(null)
    const [checkedAll, setCheckedAll] = useState(false)
    const [isIndeterminate, setIsIndeterminate] = useState(false)
    const [anchorAction, setAnchorAction] = useState(null)
    const [taskTypeOpen, setTaskTypeOpen] = useState(false)

    const [selected, setSelected] = useState([])

    const [searchDebounce] = useDebounce(search, 500)

    const refreshSelectedAll = () => {
        const selecteds = data.filter(x => selected.includes(x.id))
        setCheckedAll(selecteds.length === data.length)
        setIsIndeterminate(selecteds.length > 0 && selected.length < data.length)
    }

    useEffect(() => {
        refreshSelectedAll()
    }, [selected, data])

    const refresh = () => {
        setLoading(true)
        const url = `${global.api.base}/main/tasks`
        const data = {
            search: searchDebounce,
            limit: rowsPerPage,
            page,
            session: cookies.get('session')
        }
        axios.post(url, data).then(({data}) => {
            if(data.error) {
                console.error(data.msg)
            } else {
                setCount(data.data.count)
                setData(data.data.items)
                console.log(data)
            }
        }).catch(err => console.error(err.message)).finally(() => setLoading(false))
    }

    useEffect(refresh, [searchDebounce, rowsPerPage, page])

    useEffect(() => {
        setPage(0)
    }, [rowsPerPage, search])

    return (
        <div className='manage-admin-task-page'>
            <AddTaskTypeDialog
                open={addTaskTypeOpen}
                onClose={() => setAddTaskTypeOpen(false)}
                global={global}
            />
            <CreateTask 
                open={createTaskOpen}
                onClose={() => setCreateTaskOpen(false)}
                global={global}
                onCreated={refresh}
            />
            <TimelineDialog 
                open={timelines !== null}
                onClose={() => setTimelines(null)}
            />
            <TaskType 
                open={taskTypeOpen}
                onClose={() => setTaskTypeOpen(false)}
                global={global}
                onAdd={() => setAddTaskTypeOpen(true)}
            />
            {/* Action anchor */}
            <Menu
                anchorEl={anchorAction}
                open={Boolean(anchorAction)}
                onClose={() => setAnchorAction(null)}
            >
                <MenuItem>Hapus</MenuItem>
                <MenuItem>Tandai Selesai</MenuItem>
            </Menu>
            {/* End of action anchor */}
            <div className="heading flex items-center px-3 py-3">
                <div className="left flex items-center w-fit gap-4">
                    <Button
                        variant='contained'
                        disabled={taskTypeOpen}
                        onClick={() => setTaskTypeOpen(true)}
                    >Tipe Tugas</Button>
                    <TextField
                        variant='outlined'
                        label='Cari'
                        size='small'
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </div>
                {/* Right */}
                <div className="right flex items-center w-fit ml-auto mr-0 gap-4">
                    {selected.length > 0 && (
                        <div className='flex items-center gap-2 px-2'>
                            <div className="text">{selected.length} Terpilih</div>
                            <IconButton className='' size='small'
                                onClick={() => setSelected([])}
                            >
                                <CloseIcon className='text-xs' />
                            </IconButton>
                        </div>
                    )}
                    {selected.length > 0 && (
                        <Button
                            variant='outlined'
                            onClick={e => setAnchorAction(e.currentTarget)}
                        >Aksi Sekaligus</Button>
                    )}
                    <Button variant='contained'
                        onClick={() => setCreateTaskOpen(true)}
                    >Buat Tugas</Button>
                </div>
            </div>
            <TableContainer className='px-4 table-container'>
                <Table
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCell padding='none' width={10}>
                                <Checkbox 
                                    size='small'
                                    checked={checkedAll}
                                    indeterminate={isIndeterminate}
                                    onChange={e => {
                                        let x = [...selected]
                                        const ids = data.map(x => x.id)
                                        if(e.target.checked) {
                                            for(const id of ids) {
                                                if(!x.includes(id)) { x.push(id) }
                                            }
                                        } else {
                                            for(const id of ids) {
                                                if(x.includes(id)) { x = x.filter(y => y !== id) }
                                            }
                                        }
                                        setSelected(x)
                                    }}
                                ></Checkbox>
                            </TableCell>
                            <TableCell>#</TableCell>
                            <TableCell>Nama</TableCell>
                            <TableCell>Admin</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Deadline</TableCell>
                            <TableCell>Timeline</TableCell>
                            <TableCell>Dibuat</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='min-w-full'>
                        {data.length > 0 && data.map((item, i) => (
                            <TableRow className='odd:bg-gray-200 hover:bg-gray-300' key={i}>
                                <TableCell padding='none' width={10}>
                                    <Checkbox
                                        size='small'
                                        checked={selected.includes(item.id)}
                                        onChange={(e) => {
                                            let x = [...selected]
                                            if(e.target.checked) {
                                                x.push(item.id)
                                            } else {
                                                x = x.filter(y => y !== item.id)
                                            }
                                            setSelected(x)
                                        }}
                                    ></Checkbox>
                                </TableCell>
                                <TableCell width={20} className='text-lg text-center font-bold' padding='none'>{i+1}</TableCell>
                                <TableCell className='' padding='none'>{item.name}</TableCell>
                                <TableCell padding='none'>
                                    <div className='flex items-center gap-2 py-1'>
                                        <Avatar src={item.admin.avatar ? item.admin.avatar : `${global.api.base}/avatar/${item.user_uid}`} 
                                            className='w-4'
                                            alt={item.admin.name}
                                            title={item.admin.name}
                                        />
                                        <div className="info">
                                            <div className="name font-bold text-bold text-gray-700">{item.admin.name}</div>
                                            <div className="email text-xs font-italic text-gray-500">{item.admin.email}</div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell padding='none'>
                                    <div>
                                        <div className='text-sm w-fit text-white px-2 py- rounded-lg' style={{
                                            background: item.status.color
                                        }}>{item.status.name}</div>
                                        <div className='text-xs font-italic mt-1'>{moment(item.status.at * 1000).tz('Asia/Jakarta').format('DD.MM.YYYY HH:mm')}</div>
                                    </div>
                                </TableCell>
                                <TableCell padding='none'>
                                    {item.deadline === null && <div>-</div>}
                                    {item.deadline !== null && (
                                        <div>
                                            <div>{moment(item.deadline * 1000).tz('Asia/Jakarta').format('DD MMMM YYYY HH:mm')}</div>
                                            {item.finished === 0 && (
                                                <div className='text-white bg-green-700 px-1 py-0 w-fit rounded-lg text-xs shadow-2xl'>{moment(item.deadline * 1000).fromNow()}</div>
                                            )}
                                            {item.finished === 1 && (
                                                <div className='text-white bg-blue-700 px-1 py-0 w-fit rounded-lg text-xs shadow-2xl'>Selesai</div>
                                            )}
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell padding='none'>
                                    <Button size='small'
                                       variant='contained' 
                                       onClick={() => setTimelines(item.timelines)}
                                    >Lihat Timeline</Button>
                                </TableCell>
                                <TableCell padding='none'>
                                    <div>{moment(item.createdAt * 1000).tz('Asia/Jakarta').format('DD MMMM YYYY HH:mm')}</div>
                                </TableCell>
                            </TableRow>
                        ))}
                        {data.length < 1 && (
                            <TableRow
                            >
                                <TableCell colSpan={8} className='text-center flex items-center'>
                                    <Alert severity='warning'>No Record Found</Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                page={page}
                onPageChange={(e, v) => setPage(v)}
                count={count}
                sx={{
                    '& .MuiTablePagination-actions':{
                      display: loading ? 'none !important' : ''
                    },
                  }}
             />
        </div>
    )
}