import global from "../Global";
import { Typography } from "@material-ui/core";

export default function CenterContent({ ...props }) {
  return (
    <div className="center" {...props}>
      <p className="title">
        Selamat datang di <b>{global.appname}</b>
      </p>
      <div className="subtitle">Made with ♥ by {global.author}</div>
    </div>
  );
}
