import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { IconButton } from '@mui/material'
import {
    Tooltip,
    Card,
    CardContent,
    TextField,
    Button,
    LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function LostMoney({global}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [lost, setLost] = useState(0)

    useEffect(() => {
        if(open) {
            setLoading(true)
            const url = `${global.api.base}/main/get-lost-money`
            axios.post(url, {
                session: cookies.get('session')
            })
            .finally(() => setLoading(false))
            .then(({data}) => {
                const amount = data.data.amount
                setLost(amount)
            })
            .catch(console.error)
        }
    }, [open])

    const handleChangeValue = (e) => {
        try {
          setLost(parseInt(onlyNumber(e.target.value)));
        } catch (err) {
          setLost(0);
        }
      };

      const handleChange = () => {
        setLoading(true)
        const url = `${global.api.base}/main/update-lost-money`
        const params = { session: cookies.get('session'), lost: lost }
        axios.post(url, params)
        .then((data) => {
            setOpen(false)
        })
        .finally(() => setLoading(false))
        .catch(console.error)
      }


    return (
        <>
            <IconButton size='small' onClick={() => setOpen(x => !x)}>
                <MoneyOffIcon />
            </IconButton>
            {open && (
                <Card
                    sx={{
                        boxShadow:
                            "0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)",
                        zIndex: 100000,
                        position: "fixed",
                        top: "50px",
                        right: "90px",
                        width: "350px",
                    }}
                >{loading && <LinearProgress />}
                <CardContent className="card-open">
                  <TextField
                    size="small"
                    label="Input Barang Hilang"
                    value={formatRupiah(lost, "Rp ")}
                    onChange={handleChangeValue}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleChange}
                    disabled={loading}
                  >
                    Ok
                  </Button>
                </CardContent></Card>
            )}
        </>
    )
}


function formatRupiah(angka, prefix) {
    angka = angka.toString();
    var number_string = angka.replace(/[^,\d]/g, "").toString(),
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  
    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }
  
    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    const min = parseInt(angka) < 0 ? "-" : "";
    return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  }
  
  function onlyNumber(str) {
    return str.match(/\d/g).join("");
  }
  