import NothingImage from "../../Static/Images/Empty-amico.png";

export default function Nothing() {
  return (
    <div className="nothing">
      <img src={NothingImage} alt="Nothing" className="nothing-image" />
      <span className="nothing-title">Whoops</span>
      <span className="nothing-subtitle">
        Tidak ada data orderan yang tertunda
      </span>
    </div>
  );
}
