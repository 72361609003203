import { useEffect, useState } from "react";
import "./Admin.scss";
import { CircularProgress, Button, IconButton, Tooltip } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddAccountModal from "./AddAccountModal";

import axios from "axios";
import Cookies from "universal-cookie";
import AdminTable from "./AdminTable";

const cookies = new Cookies();
export default function Admin({ global }) {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [modalAddOpen, setModalAddOpen] = useState(false);

  const handleOpenAddModal = () => setModalAddOpen(true);
  const handleCloseAddModal = () => setModalAddOpen(false);

  function loadData() {
    const session = cookies.get("session");
    if (session) {
      setLoading(true);
      axios({
        method: "POST",
        data: {
          session,
        },
        url: global.api.base + "/main/admin-list",
      })
        .then((response) => {
          setListData(response.data.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  function handleOnAdded() {
    setModalAddOpen(false);
    loadData();
  }

  return (
    <div className="admin">
      {loading && (
        <div className="loading">
          <CircularProgress className="bar" />
          <div className="title">Tunggu sebentar ...</div>
        </div>
      )}
      {!loading && (
        <>
          <AddAccountModal
            onAdded={handleOnAdded}
            open={modalAddOpen}
            onClose={handleCloseAddModal}
          />
          <div className="header">
            <div className="actions">
              <Tooltip title="Tambahkan admin baru">
                <IconButton onClick={handleOpenAddModal.bind(this)}>
                  <PersonAddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <AdminTable onRefresh={loadData} data={listData} />
        </>
      )}
    </div>
  );
}
