import {
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import global from "../../Global";
import RangeToggle from "./RangeToggle";
import knineIcon from "../../Static/Images/logo.png";
import HelpIcon from "@mui/icons-material/Help";
import firstImage from "../../Static/Images/1st.png";
import secondImage from "../../Static/Images/2nd.png";
import threeImage from "../../Static/Images/3rd.png";
import Global from "../../Global";

export default function GreatestAdmin({ ...props }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [selectedRange, setSelectedRange] = useState("week");

  useEffect(() => {
    if (data.profit_terbanyak && data.profit_terbanyak.weekly) {
      setWeeklyData(data.profit_terbanyak.weekly);
      setMonthlyData(data.profit_terbanyak.monthly);
    }
  }, [data]);

  function loadData() {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/greatest-admin",
    }).then((response) => {
      const res = response.data;
      if (res.error) {
        console.error(res);
      } else {
        setData(res.data);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card {...props}>
      {loading && <LinearProgress />}
      <CardContent style={{ backgroundColor: "transparent !important" }}>
        {loading && <Loading />}

        {!loading && (
          <>
            <RangeToggle
              value={selectedRange}
              onChange={(v) => setSelectedRange(v)}
              opts={["week", "month"]}
            />
            <div className="rank-title" style={{ color: "#fff" }}>
              LEADERBOARD
              <Tooltip title="Admin terbaik berdasarkan profit">
                <HelpIcon sx={{ fontSize: "13px" }} />
              </Tooltip>
            </div>

            <Rank data={selectedRange === "week" ? weeklyData : monthlyData} />
          </>
        )}
      </CardContent>
    </Card>
  );
}

function Rank({ data }) {
  return (
    <div className="rank d-flex justify-content-center">
      {data.map((x, i) => (
        <Item key={i} data={x} />
      ))}
    </div>
  );
}

function Item({ data, ...props }) {
  function badge() {
    switch (data.rank) {
      case 1:
        return firstImage;
        break;
      case 2:
        return secondImage;
        break;
      case 3:
        return threeImage;
        break;
    }
  }

  return (
    data.rank <= 3 && (
      <div {...props} className={`col-lg-1 col-md-1 item rank-${data.rank}`}>
        <div className="rank-title" data-rank={data.rank}>
          {data.rank == 1 ? "1st" : data.rank == 2 ? "2nd" : "3rd"}
        </div>
        <div className="people">
          <div className="name" data-name={data.name}>
            {data.name}
          </div>
          <img className="badge" src={badge()} />
          <img
            src={
              data.avatar
                ? `${Global.api.base}/avatar/${data.uid}?w=70`
                : `https://ui-avatars.com/api/?background=random&name=${data.name}&rounded=true`
            }
            title={data.name}
            className="avatar"
          />
        </div>
      </div>
    )
  );
}

function Loading() {
  return (
    <div className="loading">
      <CircularProgress />
    </div>
  );
}
