import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default function Edit({ global, open, ai, onClose, stores }) {
  // States
  const [loading, setLoading] = useState(false);

  // values
  const [invoice, setInvoice] = useState("");
  const [pembeli, setPembeli] = useState("");
  const [produk, setProduk] = useState("");
  const [hargaModal, setHargaModal] = useState("");
  const [hargaJual, setHargaJual] = useState("");
  const [urlProduk, setUrlProduk] = useState("");
  const [namaToko, setNamaToko] = useState("");
  const [alasan, setAlasan] = useState("");
  const [blockUpdate, setBlockUpdate] = useState(false);
  const [estimasiProfit, setEstimasiProft] = useState(0);

  useEffect(() => {
    const hj =
      onlyNumber(hargaJual.toString()).length < 1
        ? 0
        : parseInt(onlyNumber(hargaJual.toString()));
    const hm =
      onlyNumber(hargaModal.toString()).length < 1
        ? 0
        : parseInt(onlyNumber(hargaModal.toString()));

    setEstimasiProft(hj - hm);
  }, [hargaJual, hargaModal]);

  //Format rupiah harga
  useEffect(() => {
    try {
      setHargaJual(formatRupiah(onlyNumber(hargaJual), "Rp "));
    } catch (err) {}

    try {
      setHargaModal(formatRupiah(onlyNumber(hargaModal), "Rp "));
    } catch (err) {}
  }, [hargaJual, hargaModal]);

  useEffect(() => {
    var modal = 0;
    var jual = 0;
    try {
      modal = parseInt(onlyNumber(hargaModal));
    } catch (er) {}
    try {
      jual = parseInt(onlyNumber(hargaJual));
    } catch (err) {}

    if (pembeli.length < 3 || pembeli.length > 50) {
      setBlockUpdate(true);
    } else if (modal <= 0) {
      setBlockUpdate(true);
    } else if (jual <= 0) {
      setBlockUpdate(true);
    } else if (produk.length < 3 || produk.length > 70) {
      setBlockUpdate(true);
    } else if (invoice.length < 5) {
      setBlockUpdate(true);
    } else if (!isValidUrl(urlProduk)) {
      setBlockUpdate(true);
    } else if (namaToko.length < 3) {
      setBlockUpdate(true);
    } else {
      setBlockUpdate(false);
    }
  }, [pembeli, produk, hargaModal, hargaJual, urlProduk, namaToko]);

  function getPembukuanData() {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/get-single-pembukuan-data",
      data: {
        session: cookies.get("session"),
        ai,
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(response.data);
        } else {
          setInvoice(res.data.invoice);
          setNamaToko(res.data.toko);
          setPembeli(res.data.pembeli);
          setProduk(res.data.produk);
          setUrlProduk(res.data.url_produk);
          setHargaModal(formatRupiah(res.data.harga_modal, "Rp "));
          setHargaJual(formatRupiah(res.data.harga_jual, "Rp "));
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    if (open) {
      getPembukuanData();
    }
  }, [open, ai]);

  function handleUpdate() {
    setLoading(true);

    var jual = 0;
    var modal = 0;

    try {
      modal = parseInt(onlyNumber(hargaModal));
    } catch (err) {}
    try {
      jual = parseInt(onlyNumber(hargaJual));
    } catch (err) {}

    axios({
      method: "POST",
      url: global.api.base + "/main/request-update-pembukuan",
      data: {
        session: cookies.get("session"),
        hargaModal: modal,
        hargaJual: jual,
        toko: namaToko,
        pembeli: pembeli,
        produk,
        urlProduk,
        invoice,
        alasan,
        ai,
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        window.alert(res.message);
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  return (
    open && (
      <Dialog
        className={"edit-data"}
        open={open}
        onClose={loading ? () => {} : onClose}
      >
        {loading && <LinearProgress />}
        <DialogContent sx={{ width: 300, position: "relative" }}>
          <FormControl fullWidth className={"forms"}>
            <TextField
              size={"small"}
              label={"Kode Invoice"}
              value={invoice}
              onChange={(e) => setInvoice(e.target.value)}
              disabled={loading}
              sx={{ display: "none" }}
            />
            <Autocomplete
              options={stores}
              clearOnEscape
              fullWidth
              getOptionLabel={(stores) => stores}
              onChange={(e) => {
                setNamaToko(e.target.textContent);
              }}
              disabled={loading}
              renderInput={(params) => (
                <TextField
                  label="Nama Toko"
                  {...params}
                  size="small"
                  variant="outlined"
                  onChange={(e) => setNamaToko(e.target.value)}
                  value={namaToko}
                />
              )}
            />
            <TextField
              disabled={loading}
              size={"small"}
              label={"Pembeli"}
              value={pembeli}
              onChange={(e) => setPembeli(capitalizeWord(e.target.value))}
            />
            <TextField
              disabled={loading}
              size={"small"}
              label={"Nama Produk"}
              value={produk}
              onChange={(e) => setProduk(capitalizeWord(e.target.value))}
            />
            <TextField
              disabled={loading}
              size={"small"}
              label={"URL Produk"}
              value={urlProduk}
              onChange={(e) => setUrlProduk(e.target.value)}
            />
            <TextField
              disabled={loading}
              size={"small"}
              label={"Harga Modal"}
              value={hargaModal}
              onChange={(e) => setHargaModal(e.target.value)}
            />
            <TextField
              disabled={loading}
              size={"small"}
              label={"Harga Jual"}
              value={hargaJual}
              error={estimasiProfit < 1}
              onChange={(e) => setHargaJual(e.target.value)}
              helperText={`Profit: ${
                estimasiProfit < 1 ? "- " : ""
              }${formatRupiah(estimasiProfit, "Rp ")}`}
            />
            <TextField
              disabled={loading}
              size={"small"}
              label={"Alasan melakukan perubahan"}
              helperText={"(Optional)"}
              value={alasan}
              onChange={(e) => setAlasan(e.target.value)}
              multiline={true}
              minRows={3}
              maxRows={10}
            />
          </FormControl>
          <p style={{ fontSize: "12px", color: "dimgray" }}>
            Perubahan hanya dapat dilakukan sekali untuk setiap pembukuan,
            pastikan data yang anda masukkan sudah benar.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Batal
          </Button>
          <Button
            onClick={() => {
              handleUpdate();
            }}
            disabled={loading || blockUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

function capitalizeWord(str) {
  var s = [];
  for (var t of str.split(" ")) {
    try {
      const first = t[0].toUpperCase();
      const letter = t.slice(1);
      s.push(first + letter);
    } catch (e) {
      s.push("");
    }
  }
  return s.join(" ");
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.replace(/[^0-9]/g, "");
}

const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};
