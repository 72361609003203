import Data from "./Data";
import LaporkanPendapatan from "./LaporkanPendapatan";

export default function PembukuanHarian({ global }) {
  return (
    <div className="order-cancelation dashboard-content">
      <Data />
      {/* <LaporkanPendapatan /> */}
    </div>
  );
}
