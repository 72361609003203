import Wrapper from "../../Landing/Wrapper";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../Landing/index.scss";
import Copyright from "../../Landing/Copyright";
import Particles from "../../Landing/Particles";
import LoginCard from "../../Landing/LoginCard";
import CenterContent from "../../Landing/CenterContent";
import Regard from "./Regard";
import "./Dashboard.scss";
import Quote from "./Quote";
import GreatestAdmin from "./GreatestAdmin";

export default function Dashboard() {
  return (
    window.location.pathname === "/dashboard" && (
      <div
        className="main-landing dashboard-main-dashboard"
        style={{
          width: "100%",
          height: "calc(100vh - 48px)",
          minHeight: "calc(100vh - 48px)",
        }}
      >
        <Wrapper
          style={{
            overflow: "hidden",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "50px",
            left: 0,
          }}
        />
        <div className="row mt-4 d-flex align-items-center justify-content-center">
          <Regard className="regard col-lg-4 col-md-3" />
          <Quote className="quote col-lg-8 col-md-7" />
          <GreatestAdmin className="greatest-admin col-lg-10 col-md-11" />
        </div>
      </div>
    )
  );
}
