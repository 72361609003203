import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import {
  IconButton,
  Tooltip,
  Card,
  CardContent,
  TextField,
  Button,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function Gift({ global }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [giftData, setGifData] = useState(undefined);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (open) {
      loadGift();
    }
  }, [open]);

  function loadGift() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/gift-data",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        setGifData(res);
        setValue(res.value);
      })
      .catch((err) => {
        setLoading(false);
        window.alert(`Failed for load gift data: ${err.message}`);
      });
  }

  function handleChange() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        newValue: value,
      },
      url: global.api.base + "/main/update-gift",
    })
      .then((response) => {
        const res = response.data;
        setLoading(false);
        window.alert(res.message);
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  const handleChangeValue = (e) => {
    try {
      setValue(parseInt(onlyNumber(e.target.value)));
    } catch (err) {
      setValue(0);
    }
  };

  return (
    <div className="gift" style={{ padding: 0, margin: 0, marginRight: -3 }}>
      <Tooltip title="Gift">
        <IconButton
          onClick={() => {
            setOpen(!open);
          }}
        >
          <CardGiftcardIcon color="#eb8334" sx={{ color: "#eb8334" }} />
        </IconButton>
      </Tooltip>
      {open && (
        <Card
          sx={{
            boxShadow:
              "0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%)",
            zIndex: 100000,
            position: "fixed",
            top: "50px",
            right: "90px",
            width: "350px",
          }}
        >
          {loading && <LinearProgress />}
          <CardContent className="card-open">
            <TextField
              size="small"
              label="Input Nominal"
              value={formatRupiah(value, "Rp ")}
              onChange={handleChangeValue}
            />
            <Button
              variant="contained"
              size="small"
              onClick={handleChange}
              disabled={loading}
            >
              Ok
            </Button>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.match(/\d/g).join("");
}
