import { Alert, AppBar, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Fab, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import { useEffect, useState } from "react";
import PlusIcon from '@mui/icons-material/Add'
import { CirclePicker } from 'react-color'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LinkIcon from '@mui/icons-material/Link';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { EditText, EditTextarea} from 'react-edit-text'
import AddIcon from '@mui/icons-material/Add';
import AddTaskTypeDialog from "./AddTaskTypeDialog";

export default function TaskType({ global, onClose, open, onAdd }) {
    const [data, setData] = useState([])
    const [taskIdAddStatus, setTaskIdAddStatus] = useState(null)
    const [createTaskTypeOpen, setCreateTaskTypeOpen] = useState(false)

    const refresh = () => {
        const url = `${global.api.base}/main/task-types`
        axios.get(url)
            .then(({ data }) => {
                if (data.error) {
                    console.error(data.msg || data.message)
                } else {
                    setData(data.data)
                    console.log(data.data)
                }
            }).catch(err => console.error(err.message))
    }

    useEffect(() => {
        if (open) {
            refresh()
        } else {
            setData([])
        }
    }, [open])

    const removeStatus = (taskId, statusName) => {
        const url = `${global.api.base}/main/removeTaskStatus`
        const data = {id: taskId, name: statusName}
        axios.post(url, data)
            .then(({data}) => {
                if(data.error) {
                    console.error(data.msg)
                } else {
                    refresh()
                }
            })
            .catch(err => console.error(err.message))
    }

    const updateAttachStatus = (id, name, value) => {
        const url = `${global.api.base}/main/updateTaskTypeAttach`
        const data = {id, name, value}
        axios.post(url, data)
            .then(({data}) => { if(data.error) { console.error(data.msg) } })
            .catch(err => console.error(err.message))
    }       

    const changeName = (id, name) => {
        const url = `${global.api.base}/main/changeTaskTypeName`
        const data = {id, name}
        axios.post(url, data)
            .then(({data}) => { if(data.error) { console.error(data.msg) } })
            .catch(err => console.error(err.message))
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
        >
            <AddTaskTypeDialog
                open={createTaskTypeOpen}
                onClose={() => setCreateTaskTypeOpen(false)}
                global={global}
                onAdded={() => refresh()}
            />
            <AddStatus 
                open={taskIdAddStatus !== null}
                onClose={() => setTaskIdAddStatus(null)}
                taskId={taskIdAddStatus}
                global={global}
                onAdded={refresh}
            />
            <AppBar sx={{
                position: 'relative'
            }}>
                <Toolbar>
                    <IconButton onClick={onClose}>
                        <CloseIcon className="text-white" />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Tipe Tugas</Typography>
                </Toolbar>

            </AppBar>
            <DialogContent>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell padding='none'>#</TableCell>
                                <TableCell padding='none'>Nama</TableCell>
                                <TableCell padding='none'>Daftar Status</TableCell>
                                <TableCell padding='none'>Lampiran</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell padding='none'>{i+1}</TableCell>
                                    <TableCell padding='none'>
                                        <EditText defaultValue={item.name}
                                            onSave={e => {
                                                changeName(item.id, e.value)
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Grid wrap='wrap' className="flex gap-2" style={{
                                            maxWidth: '300px',
                                            display: 'flex',
                                            flexWrap: 'wrap'
                                        }}>
                                            {item.statuses.map((status, i) => (
                                                <div className="flex items-center gap-2 bg-gray-300 w-fit px-2 rounded-lg hover:bg-gray-200" key={i}>
                                                    <div className="name">{status.name}</div>
                                                    <div style={{
                                                        background: status.color
                                                    }} className="px-1 py-1 w-fit rounded-lg"></div>
                                                    <button className="hover:bg-gray-500 hover:text-white"
                                                        onClick={() => removeStatus(item.id, status.name)}
                                                    >
                                                        <CloseIcon className="text-gray-700 text-xs" fontSize="12px" />
                                                    </button>
                                                </div>
                                            ))}
                                            <button className="text-white bg-gray-700 text-xs w-5 h-5  rounded hover:bg-gray-800"
                                                onClick={() => setTaskIdAddStatus(item.id)}
                                            >
                                                <PlusIcon fontSize="30px" />
                                            </button>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex items-center gap-4">
                                            <div className="bg-gray-200 flex items-center w-fit pr-3 rounded-lg hover:bg-gray-300">
                                                <Checkbox
                                                    size='small'
                                                    defaultChecked={item.attachFile === 1}
                                                    onChange={e => {
                                                        updateAttachStatus(item.id, 'attachFile', e.target.checked ? 1 : 0)
                                                    }}
                                                />
                                                <InsertDriveFileIcon className="" />
                                                <div className="text font-bold ml-1 mt-1">File</div>
                                            </div>
                                            <div className="bg-gray-200 flex items-center w-fit pr-3 rounded-lg hover:bg-gray-300">
                                                <Checkbox
                                                    size='small'
                                                    defaultChecked={item.attachUrls === 1}
                                                    onChange={e => {
                                                        updateAttachStatus(item.id, 'attachUrls', e.target.checked ? 1 : 0)
                                                    }}
                                                />
                                                <LinkIcon className="" />
                                                <div className="text font-bold ml-1 mt-1">Url</div>
                                            </div>
                                            <div className="bg-gray-200 flex items-center w-fit pr-3 rounded-lg hover:bg-gray-300">
                                                <Checkbox
                                                    size='small'
                                                    defaultChecked={item.attachNote === 1}
                                                    onChange={e => {
                                                        updateAttachStatus(item.id, 'attachNote', e.target.checked ? 1 : 0)
                                                    }}
                                                />
                                                <EditNoteIcon className="" />
                                                <div className="text font-bold ml-1 mt-1">Catatan</div>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="fixed bottom-3 right-3">
                    <Fab variant='extended' size='small' color='primary' onClick={() => setCreateTaskTypeOpen(true)} className="pr-3">
                        <AddIcon className="mr-2" />
                        Tambah
                    </Fab>
                </div>
            </DialogContent>
        </Dialog>
    )
}

function AddStatus({open, onClose, taskId, onAdded, global}) {
    const [name, setName] = useState('')
    const [color, setColor] = useState('#1976d2')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        setName('')
        setError(null)
        setColor('#1976d2')
    }, [open])

    const add = () => {
        setError(null)
        const url = `${global.api.base}/main/addTaskStatus`
        const data = { taskId, name, color }
        axios.post(url, data)
            .then(({data}) => {
                if(data.error) {
                    setError(data.msg)
                } else {
                    onClose()
                    onAdded()
                }
            })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false))
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <div className="text-sm">Tambah Status</div>
            </DialogTitle>
            <DialogContent>
                <div className="py-2" style={{
                    width: '240px'
                }}>
                    {error && (
                        <Alert severity='error' className="mb-4">{error}</Alert>
                    )}
                    <TextField 
                        size='small'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        label='Nama'
                        error={name.trim().length < 1}
                        helperText={name.trim().length < 1 ? 'Nama terlalu pendek' : undefined}
                        autoFocus
                        fullWidth
                    />
                    <CirclePicker 
                        color={color}
                        onChange={e => setColor(e.hex)}
                        className="mt-4"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button size='small' variant='outlined'
                    disabled={name.trim().length < 1 || loading}
                    onClick={add}
                >Tambah</Button>
            </DialogActions>
        </Dialog>
    )
}