import {
    Dialog,
    DialogActions,
    DialogHeader,
    DialogContent,
    Button,
    TextField
} from '@mui/material'
import './action-cookies.scss'
import {useEffect, useState} from 'react'
import config from '../../Global'
import axios from 'axios'
import SaveIcon from '@mui/icons-material/Save'

export default function ActionCookies({open, onClose, ...params}) {
    const [loading, setLoading] = useState(false)
    const [rawCookies, setRawCookies] = useState('')

    const save = () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            const url = `${config.api.base}/main/set-account-cookies`
            const args = {ai: params.id, raw_cookies: rawCookies}
            axios.post(url, args)
                .then(({data}) => {
                    setLoading(false)
                    if(data.error) {
                        reject(data.reason)
                    } else {
                        resolve(data.reason)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    reject(err.message)
                })
        })
    }

    const handleSave = () => {
        save().then((reason) => {
            onClose()
        })
            .catch(err => window.alert(err))
    }

    const getRawCookies = () => {
        return new Promise((resolve, reject) => {
            const url = `${config.api.base}/main/get-account-cookies?ai=${params.id}`
            axios.get(url).then(({data}) => {
                if(data.error) {
                    reject(data.reason)
                } else {
                    resolve(data.raw_cookies)
                }
            }).catch(err => reject(err.message))
        })
    }

    useEffect(() => {
        if(open) {
            setLoading(true)
            getRawCookies().then(rawCookies => {
                setLoading(false)
                setRawCookies((rawCookies))
            }).catch(err => {
                setLoading(false)
                setRawCookies('')
                console.error(err)
            })
        }
        setRawCookies('')
    }, [open])


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <div className='action-cookies'>
                    <div className="form">
                        <TextField
                            value={rawCookies}
                            onChange={e => setRawCookies(e.target.value)}
                            multiline={true}
                            placeholder='Inline Cookies'
                            label='Inline Cookies'/>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} variant={'contained'} color={'error'} onClick={onClose}>Batal</Button>
                <Button variant='contained' onClick={handleSave} disabled={loading} startIcon={<SaveIcon />}>Simpan</Button>
            </DialogActions>
        </Dialog>
    )
}