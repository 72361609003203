import {
  IconButton,
  Tooltip,
  List,
  ListItemText,
  ListItem,
  ListItemButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment-timezone";
import "moment/locale/id";
import CloseIcon from "@mui/icons-material/Close";

export default function Data({ data, onAddRequest, onDelete, onOpen }) {
  function handleDelete(ai) {
    if (
      window.confirm(`Anda yakin ingin menghapus data market share profit ?`)
    ) {
      onDelete(ai);
    }
  }

  return (
    <div className="data">
      <Tooltip title="Tambahkan market baru">
        <IconButton onClick={onAddRequest} size="small" className="add-request">
          <AddIcon />
        </IconButton>
      </Tooltip>

      <List className="list-container">
        {data.map((row, index) => (
          <ListItem key={index} className="list-item">
            <ListItemButton onClick={() => onOpen(row.ai)}>
              <ListItemText
                primary={row.market}
                primaryTypographyProps={{
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
                secondary={`Terakhir update: ${moment(
                  row.last_updated * 1000
                ).fromNow()} oleh ${row.last_updated_by}`}
                secondaryTypographyProps={{ fontSize: "12px" }}
              />
            </ListItemButton>
            <ListItemButton onClick={handleDelete.bind(this, row.ai)}>
              <CloseIcon />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
