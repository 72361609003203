import {
  Card,
  TextField,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Divider,
} from "@mui/material";
import "./Profile.scss";
import BadgeIcon from "@mui/icons-material/Badge";
import SaveIcon from "@mui/icons-material/Save";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Avatar from "./Avatar";

const cookies = new Cookies();
export default function Profile({ global }) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [uid, setUid] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function loadData() {
    axios({
      method: "POST",
      url: global.api.base + "/main/profile-detail",
      data: {
        session: cookies.get("session"),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error) {
          window.alert(res.data.message);
        }
        console.log(res.data);
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setAvatar(res.data.data.avatar);
        setUid(res.data.data.uid);
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  function changePassword() {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/update-password",
      data: {
        password,
        session: cookies.get("session"),
      },
    })
      .then((res) => {
        setLoading(false);
        window.alert(res.data.message);
        if (!res.data.error) {
          setPassword("");
          setConfirmPassword("");
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  function handleChangePassword() {
    if (password.length < 7) {
      window.alert("Password harus setidaknya 7 karakter");
    } else if (password.length > 100) {
      window.alert("Password harus kurang dari 100 karakter");
    } else if (password !== confirmPassword) {
      window.alert("Password tidak sama");
    } else {
      changePassword();
    }
  }

  return (
    <Paper className="profile dashboard-content">
      {loading && <LinearProgress />}
      <div className="wrapper">
        <div className="profile-header">
          <BadgeIcon className="badge-icon" />
          <span>Profile</span>
        </div>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <div className="form">
          <TextField
            disabled={true}
            variant="outlined"
            label="Nama"
            size="small"
            value={name}
          />
          <TextField
            disabled={true}
            value={email}
            variant="outlined"
            label="Email"
            size="small"
          />
          <Avatar avatar={avatar} uid={uid} onChange={loadData} />
          <Divider />
          <TextField
            disabled={loading}
            variant="outlined"
            label="Password"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
          <TextField
            variant="outlined"
            label="Konfirmasi password"
            size="small"
            disabled={loading}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            className="btn-save"
            disabled={loading}
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleChangePassword}
          >
            Simpan
          </Button>
        </div>
      </div>
    </Paper>
  );
}
