import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Quote({ props }) {
  const [quote, setQuote] = useState("");
  const [quoteCreator, setQuoteCreator] = useState("");
  const [quotesLoading, setQuotesLoading] = useState(false);
  const [hovered, setHovered] = useState(false);

  function getQuote() {
    setQuotesLoading(true);
    axios({
      method: "GET",
      url: "https://ziqva.com/quotes.json",
    })
      .then((response) => {
        const quotes = response.data;
        // Get the randomize
        const res = quotes[Math.floor(Math.random() * quotes.length)];
        setQuote(res.quote);
        setQuoteCreator(res.creator);
        setQuotesLoading(false);
      })
      .catch((err) => {
        setQuotesLoading(false);
        console.error("Gagal mendapatkan quotes: " + err.message);
      });
  }

  useEffect(() => {
    getQuote();
  }, []);

  return (
    !quotesLoading && (
      <Card
        {...props}
        sx={{ zIndex: 1000, position: "relative" }}
        className="quote"
      >
        <CardContent
          onMouseOver={() => {
            setHovered(true);
          }}
          onMouseOut={() => {
            setHovered(false);
          }}
          className={hovered ? "wrapper-show" : ""}
        >
          {hovered && (
            <Wrapper
              onClick={() => {
                getQuote();
                setHovered(false);
              }}
            />
          )}
          <div className="message">{quote}</div>
          <div className="creator">{quoteCreator}</div>
        </CardContent>
      </Card>
    )
  );
}

function Wrapper({ onClick }) {
  return (
    <div onClick={onClick} className="wrapper">
      Muat ulang
    </div>
  );
}
