import "./MonitorPendingOrder.scss";
import { useEffect, useState } from "react";
import Admins from "./Admins";
import DataRange from "./DataRange";
import ChangeDate from "./ChangeDate";
import ChangeMonth from "./ChangeMonth";
import Cookies from "universal-cookie";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Data from "./Data";
import { createTheme, ThemeProvider } from "@mui/system";
const cookies = new Cookies();

export default function MonitorPendingOrder({ global }) {
  const [admins, setAdmins] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [range, setRange] = useState("DAILY");
  const [epoch, setEpoch] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEpoch, setSelectedEpoch] = useState(undefined);

  function loadData({ epoch, rangeType, uids }) {
    setLoading(true);
    axios({
      method: "POST",
      url: `${global.api.base}/main/monitoring-pending-order/data`,
      data: {
        session: cookies.get("session"),
        epoch,
        rangeType,
        uids,
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          setData(res.data);
          setSelectedEpoch(res.selected_epoch);
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    if (epoch != 0 && selectedAdmins.length > 0) {
      loadData({
        epoch,
        rangeType: range,
        uids: selectedAdmins.map((x) => x.uid),
      });
    }

    if (selectedAdmins.length < 1) {
      setLoading(false);
      setData([]);
    }
  }, [epoch, range, selectedAdmins]);

  return (
    <div className="monitor-pending-order">
      <div className="left">
        <DataRange onRangeChange={(range) => setRange(range)} />
        <Range range={range} onChange={(epoch) => setEpoch(epoch)} />
        <Admins
          className="admins"
          global={global}
          onAdmins={(admins) => setAdmins(admins)}
          onSelectedAdmins={(selected) => setSelectedAdmins(selected)}
        />
      </div>
      <div className="right">
        {loading && (
          <LinearProgress
            sx={{ width: "calc(100vw - 300px)", padding: "1px" }}
          />
        )}
        <Data data={data} epoch={selectedEpoch} />
      </div>
    </div>
  );
}

function Range({ range, onChange }) {
  switch (range) {
    case "DAILY":
      return <ChangeDate onChange={onChange} />;
    case "MONTHLY":
      return <ChangeMonth onChange={onChange} />;
    default:
      return <>ERR_UNDEFINED_RANGE</>;
  }
}
