import { Card, CardContent, Divider } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import global from "../../Global";
import axios from "axios";
import moment from "moment-timezone";
import "moment/locale/id";

const cookies = new Cookies();
export default function Regard({ ...props }) {
  const [profile, setProfile] = useState(undefined);
  const [time, setTime] = useState("00:00");
  const [m, setM] = useState(moment());
  const [timeName, settimeName] = useState("...");
  const [yesterdayData, setYesterdayData] = useState(undefined);

  function getYesterdayWork() {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/yesterday-work",
    })
      .then((response) => {
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        }
        setYesterdayData(res);
      })
      .catch((err) => {
        window.alert("Gagal mendapatkan hasil kerja kemarin: " + err.message);
      });
  }

  function regard() {
    const h = new Date().getHours();
    if (h >= 24 && h <= 8) {
      return "pagi";
    } else if (h >= 8 && h <= 13) {
      return "siang";
    } else if (h >= 13 && h <= 18) {
      return "sore";
    } else if (h >= 18 && h <= 24) {
      return "malam";
    }
  }

  function setMoment() {
    setM(moment());
    settimeName(regard());
  }

  useEffect(() => {
    const momentInterval = setInterval(() => {
      setMoment();
    }, 1000);
    return () => {
      clearInterval(momentInterval);
    };
  }, []);

  function getProfile() {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/profile-detail",
    })
      .then((response) => {
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        }
        setProfile(res.data);
      })
      .catch((err) => {
        window.alert(`Failed for get profile: ${err.message}`);
      });
  }

  useEffect(() => {
    getProfile();
    getYesterdayWork();
  }, []);

  return (
    <Card {...props}>
      <CardContent>
        <div className="regard-main">
          Hai <b>{profile ? profile.name : "..."}</b>, selamat {timeName}. Dan
          good job, kemaren kamu sudah mengerjakan{" "}
          <b>{yesterdayData ? yesterdayData.order_complete : 0}</b> Orderan
          dengan baik.
        </div>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <div className="clock d-flex align-items-center justify-content-center">
          <WatchLaterIcon className="icon" />
          <div className="time">{m.tz("Asia/Jakarta").format("HH:mm")}</div>
        </div>
      </CardContent>
    </Card>
  );
}
