import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import JsFileDownloader from "js-file-downloader";

const cookies = new Cookies();

export default function Export({ global }) {
  const deleteCallback = (filename) => {
    axios
      .post(global.api.base + "/main/deleteAccountExportCallback", {
        filename,
      })
      .then(() => {})
      .catch(() => {});
  };

  const [loading, setLoading] = useState(false);

  const handleExport = () => {
    const url = global.api.base + "/main/export-account";
    const session = cookies.get("session");
    setLoading(true);
    axios
      .post(url, { session })
      .then((response) => {
        const { fileName } = response.data;
        //   console.log(fileName);
        new JsFileDownloader({
          url: global.api.base + "/" + fileName,
        })
          .then(() => {
            setLoading(false);
            deleteCallback(fileName);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <div className="bulk-add" style={{ padding: 0, margin: 0, marginLeft: -5 }}>
      <Button
        disabled={loading}
        variant="contained"
        size="small"
        className="btn-add"
        onClick={handleExport}
      >
        {loading ? "..." : "Export"}
      </Button>
    </div>
  );
}
