import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./PembukuanHarian.scss";
import moment from "moment-timezone";
import "moment/locale/id";
import Actions from "./Actions";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { Autocomplete, CircularProgress, LinearProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Cookies from "universal-cookie";
import global from "../../Global";
import MoveDate from "./MoveDate";
import Edit from "./Edit";

const cookies = new Cookies();

function createData(
  incrementRows,
  namaPembeli,
  namaProduk,
  namaToko,
  hargaModal,
  hargaJual,
  profit,
  invoice,
  productUrl,
  adminName
) {
  return {
    incrementRows,
    namaPembeli,
    namaProduk,
    namaToko,
    hargaModal,
    hargaJual,
    profit,
    invoice,
    productUrl,
    adminName,
  };
}

// const rows = [
//   createData(0, "Sanuri", "Sandal", "Sanuri DKK", 10000, 100000, 1),
// ];

export default function Data() {
  const [rows, setRows] = useState([]);
  const [userName, setUserName] = useState("...");
  const [loading, setLoading] = useState(false);
  const [strTanggal, setStrTanggal] = useState("...");
  const [totalModal, setTotalModal] = useState(0);
  const [omset, setOmset] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [editAi, setEditAi] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [stores, setStores] = useState([]);

  const columns = [
    {
      id: "incrementRows",
      label: "#",
      format: (e) => {
        return <div style={{ marginLeft: "10px" }}>{e}</div>;
      },
    },
    {
      id: "invoice",
      label: "Invoice",
      align: "left",
      maxWidth: 50,
    },
    {
      id: "namaToko",
      label: "Nama Toko",
      align: "left",
    },
    { id: "namaPembeli", label: "Nama Pembeli", capitalize: true },
    {
      id: "namaProduk",
      label: "Pcs",
      align: "left",
    },
    {
      id: "productUrl",
      label: "URL Produk",
      align: "left",
      format: (productUrl) => (
        <div
          className="urls"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {productUrl.split("$$$$").map((url) => (
            <a href={url} target="_blank" rel="noreferrer">
              {url.length > 35 ? url.slice(0, 35) + "..." : url}
            </a>
          ))}
        </div>
      ),
    },
    {
      id: "hargaModal",
      label: "Harga Modal",
      align: "left",
      format: (value) => formatRupiah(value.toString(), "Rp "),
    },
    {
      id: "hargaJual",
      label: "Harga Jual",
      format: (value) => formatRupiah(value.toString(), "Rp "),
      align: "left",
    },
    {
      id: "profit",
      label: "Profit",
      format: (value) => (
        <div
          style={{
            color: value < 0 ? "red" : "#000",
          }}
        >
          {value < 0 ? "- " : ""}
          {formatRupiah(value.toString(), "Rp ")}
        </div>
      ),
      align: "left",
    },
    {
      id: "adminName",
      label: "Admin",
    },
  ];

  function getData(epoch) {
    setLoading(true);
    setStrTanggal("...");
    setUserName("...");
    setOmset(0);
    setTotalModal(0);
    setTotalProfit(0);
    setRows([]);
    const current_moment = moment(epoch * 1000).tz("Asia/Jakarta");
    axios({
      method: "POST",
      url: global.api.base + "/main/get-data-pembatalan",
      data: {
        tanggal: current_moment.format("D"),
        bulan: current_moment.format("M"),
        tahun: current_moment.format("YYYY"),
        session: cookies.get("session"),
      },
    })
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.message);
        }
        var d = [];
        var tm = 0; // total modal
        var o = 0; // omset
        var p = 0; // profit
        for (var row of res.data.data) {
          console.log(row);
          d.push(
            createData(
              res.data.data.indexOf(row) + 1,
              row.pembeli,
              row.produk,
              row.toko,
              row.hargaModal,
              row.hargaJual,
              row.profit,
              row.invoice,
              row.productUrl,
              row.adminName
            )
          );
          p += row.hargaJual - row.hargaModal;
          tm += row.hargaModal;
          o += row.hargaJual;
        }
        setTotalProfit(p);
        setOmset(o);
        setTotalModal(tm);
        setRows(d);
        setLoading(false);
        setUserName(res.data.user.name);
        setStrTanggal(current_moment.format("MMMM YYYY"));
        document.title = `${current_moment.format("MMMM YYYY")} Order Batal`;
      })
      .catch((err) => {
        setRows([]);
        setStrTanggal("...");
        setUserName("...");
        window.alert(err.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    // init
    getData(moment().tz("Asia/Jakarta").unix());
  }, []);
  const handleEditClose = () => setEditOpen(false);

  return (
    <>
      <Paper sx={{ width: "100%", height: "100%" }}>
        <TableContainer className="dt-container">
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="tcell" align="center" colSpan={8}>
                  {strTanggal} ~ {userName}
                </TableCell>
                <TableCell className="tcell" align="center" colSpan={2}>
                  {/* Tempat ubah tanggal sebelumnya */}
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="nowrap"
                    key={column.id}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column, iCol) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          sx={{
                            padding: 0,
                            width: "fit-content",
                            maxWidth:
                              column.id === "invoice" ? "100px" : "unset",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className={`nowrap${
                            column.capitalize ? " capitalize" : ""
                          }${iCol == 0 ? " num" : ""}`}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableRow sx={{ backgroundColor: "#ffab91" }}>
              <TableCell sx={{ fontWeight: "500", fontSize: "17px" }}>
                SUB TOTAL
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ padding: 0 }}>
                Modal:
                {totalModal < 0 ? "- " : ""}
                <br />
                {formatRupiah(totalModal.toString(), "Rp ")}
              </TableCell>
              <TableCell sx={{ padding: 0 }}>
                Omset: {omset < 0 ? "- " : ""}
                <br />
                {formatRupiah(omset.toString(), "Rp ")}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow sx={{ padding: 0 }}>
              <TableCell colspan={2}>
                <MoveDate
                  onChange={(epoch) => {
                    getData(epoch);
                  }}
                />
              </TableCell>
              {/* <TableCell></TableCell>
              <TableCell></TableCell> */}
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell
                colspan={3}
                sx={{
                  backgroundColor: "#ff7043",
                  fontSize: "18px",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                Profit: {totalProfit < 0 ? "- " : ""}
                {formatRupiah(totalProfit.toString(), "Rp ")}
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
      <Edit
        ai={editAi}
        stores={stores}
        open={editOpen}
        onClose={handleEditClose}
        global={global}
      />
      <Add
        onStores={(s) => setStores(s)}
        onAdded={() => {
          getData(moment().tz("Asia/Jakarta").unix());
        }}
      />
    </>
  );
}

function Add({ onAdded, onStores }) {
  const [namaPembeli, setNamaPembeli] = useState("");
  const [namaProduk, setNamaProduk] = useState("");
  const [namaToko, setNamaToko] = useState("");
  const [hargaJual, setHargaJual] = useState("");
  const [hargaModal, setHargaModal] = useState("");
  const [stores, setStores] = useState([]);
  const [invoice, setInvoice] = useState("");
  const [urlProduk, setUrlProduk] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDisableAdd(
      namaPembeli.trim().length < 1 ||
        namaProduk.trim().length < 1 ||
        namaToko.trim().length < 1 ||
        hargaJual.trim().length < 1 ||
        hargaModal.trim().length < 1 ||
        invoice.trim().length < 1 ||
        !isValidUrl(urlProduk)
    );
  }, [
    namaPembeli,
    namaProduk,
    namaToko,
    hargaJual,
    hargaModal,
    invoice,
    urlProduk,
  ]);
  const [disabeAdd, setDisableAdd] = useState(false);

  useEffect(() => {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/stores",
    })
      .then((response) => {
        const res = response.data;

        if (res.error) {
          window.alert(res.message);
        } else {
          setStores(res);
          onStores(res);
          setNamaPembeli("");
          setNamaProduk("");
          setNamaToko("");
          setHargaJual("");
          setHargaModal("");
          setInvoice("");
          setUrlProduk("");
        }
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }, []);

  const handleAdd = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/tambah-data-pembukuan",
      data: {
        namaPembeli,
        namaProduk,
        hargaModal,
        hargaJual,
        namaToko,
        session: cookies.get("session"),
        invoice,
        urlProduk,
      },
    })
      .then((response) => {
        setLoading(false);
        window.alert(response.data.message);
        if (!response.data.error) {
          setNamaToko("");
          setHargaJual("");
          setHargaModal("");
          setNamaPembeli("");
          setNamaProduk("");
          onAdded();
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  };

  useEffect(() => {
    try {
      setHargaJual(formatRupiah(onlyNumber(hargaJual), "Rp "));
    } catch (err) {}
    try {
      setHargaModal(formatRupiah(onlyNumber(hargaModal), "Rp "));
    } catch (err) {}
  }, [hargaJual, hargaModal]);

  return (
    <div style={{ display: "none" }}>
      {loading && <LinearProgress sx={{ left: 0, bottom: 0 }} />}
      <Paper sx={{ width: "100%", height: "100%", p: 2, mt: 1 }}>
        <div className="add-forms">
          <TextField
            fullWidth
            label="Nama Pembeli"
            size="small"
            value={namaPembeli}
            onChange={(e) => setNamaPembeli(e.target.value)}
            disabled={loading}
          />
          <TextField
            fullWidth
            label="Nama Produk"
            size="small"
            value={namaProduk}
            onChange={(e) => setNamaProduk(e.target.value)}
            disabled={loading}
          />
          <Autocomplete
            options={stores}
            clearOnEscape
            fullWidth
            getOptionLabel={(opt) => opt}
            onChange={(e) => {
              setNamaToko(e.target.textContent);
            }}
            renderInput={(params) => (
              <TextField
                label="Nama Toko"
                {...params}
                size="small"
                variant="outlined"
                onChange={(e) => setNamaToko(e.target.value)}
                value={namaToko}
              />
            )}
          />
          <TextField
            fullWidth
            label="Harga Modal (Rp)"
            size="small"
            value={hargaModal}
            onChange={(e) => setHargaModal(e.target.value)}
            disabled={loading}
          />
          <TextField
            fullWidth
            label="Harga Jual (Rp)"
            size="small"
            value={hargaJual}
            onChange={(e) => setHargaJual(e.target.value)}
            disabled={loading}
          />
          {!loading && (
            <Tooltip title="Tambahkan order baru">
              <IconButton
                onClick={handleAdd}
                sx={{
                  backgroundColor: "#3367D6 !important",
                  color: "#fff",
                  height: "fit-content",
                }}
                size="small"
                disabled={loading || disabeAdd}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="add-forms" style={{ marginTop: "10px" }}>
          <TextField
            label="Invoice"
            size="small"
            value={invoice}
            onChange={(e) => setInvoice(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="URL Produk"
            size="small"
            style={{ flex: 1 }}
            value={urlProduk}
            onChange={(e) => setUrlProduk(e.target.value)}
            disabled={loading}
          />
        </div>
      </Paper>
    </div>
  );
}

function formatRupiah(angka, prefix) {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.match(/\d/g).join("");
}

const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};
