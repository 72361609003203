import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Button, Input, TextField, Checkbox, Card, CardContent, IconButton, Divider, Alert, Fab } from '@mui/material'
import { useState, useEffect } from 'react'
import { CirclePicker } from 'react-color'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'universal-cookie'
import axios from 'axios'

const cookies = new Cookies()

export default function AddTaskTypeDialog({ open, onClose, global,onAdded }) {
    const [attachFile, setAttachfile] = useState(false)
    const [attachNote, setAttachNote] = useState(false)
    const [attachUrls, setAttachUrls] = useState(false)
    const [name, setName] = useState('')
    const [note, setNote] = useState('')
    const [statuses, setStatuses] = useState([]) // color, name
    const [addStatusColor, setAddStatusColor] = useState('#1976d2')
    const [addStatusName, setAddStatusName] = useState('')
    const [error, setError] = useState(null)

    useEffect(() => {
        setName('')
        setNote ('')
        setStatuses([])
        setAddStatusColor('#1976d2')
        setAddStatusName('')
        setError(null)
    }, [open])

    const [loading, setLoading] = useState(false)

    const add = () => {
        setLoading(true)
        setError(null)
        const url = `${global.api.base}/main/add-task-type`
        axios.post(url, {
            session: cookies.get('session'),
            name: name.trim(),
            attach: {
                file: attachFile,
                note: attachNote,
                urls: attachUrls
            },
            superAdminNote: note.trim(),
            statuses,
        }).then(({data}) => {
            if(data.error) {
                setError(data.msg)
            } else {
                onAdded()
                onClose()
            }
        }).catch(err => {
            console.error(err.message)
            setError(err.message)
        }).finally(() => setLoading(false))
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title='Tambah Tipe Tugas'
            maxWidth={`300px`}

        >
            <DialogTitle>Tambah Tipe Tugas</DialogTitle>
            {error && (
                <Alert
                    severity='error'
                    className='mb-4'
                >{error}</Alert>
            )}
            <DialogContent className='pt-2'>
                <div className='flex flex-col items-start'
                    style={{
                        width: '300px',
                        maxWidth: '300px'
                    }}
                >
                    <TextField
                        label='Nama'
                        size='small'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        fullWidth
                        disabled={loading}
                    />
                    <div className="flex items-center mt-2">
                        <Checkbox checked={attachFile} onChange={e => setAttachfile(e.target.checked)} 
                                disabled={loading}
                            />
                        <div className="text-md font-bold">Lampirkan File</div>
                    </div>

                    <div className="flex items-center">
                        <Checkbox checked={attachNote} onChange={e => setAttachNote(e.target.checked)} 
                                disabled={loading}
                            />
                        <div className="text-md font-bold">Lampirkan Catatan</div>
                    </div>

                    <div className="flex items-center">
                        <Checkbox checked={attachUrls} onChange={e => setAttachUrls(e.target.checked)} 
                                disabled={loading}
                            />
                        <div className="text-md font-bold">Lampirkan Urls</div>
                    </div>
                    {/* Statuses */}
                    <div className="statuses">
                    </div>
                    {/* Add status */}
                    <Card className='mt-2 w-full'>
                        <CardContent className='w-full'>
                            <div className="text-md font-bold">List Status</div>
                            <div className="statuses">
                                {statuses.map((status, i) => (
                                    <div className="status flex w-full items-center gap-2 hover:bg-gray-200" key={i}>
                                        <div className="name text-ellipsis overflow-hidden" style={{
                                            maxWidth: '210px'
                                        }}>{status.name}</div>
                                        <div className='flex w-fit items-center ml-auto mr-0 gap-1'>
                                            <div className="color p-1 w-1 h-1 rounded-xl" style={{
                                                backgroundColor: status.color
                                            }}></div>
                                            <CloseIcon fontSize='20px' className='hover:bg-gray-600 cursor-pointer hover:text-white'
                                                onClick={() => {
                                                    setStatuses(x => x.filter((y, _) => _ !== i))
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Divider />
                            <div className='items-center mt-2 flex items-center gap-2'>
                                <TextField
                                    size='small'
                                    label='Nama Status'
                                    fullWidth
                                    className='mt-2'
                                    value={addStatusName}
                                    onChange={e => setAddStatusName(e.target.value)}
                                    disabled={loading}
                                />
                                <IconButton
                                    className='bg-primary text-white mt-1 disabled:bg-gray-700 disabled:cursor-not-allowed'
                                    size='small'
                                    variant='contained'
                                    disabled={addStatusName.trim().length < 1 || loading}
                                    onClick={() => {
                                        setStatuses(x => {
                                            return [
                                                {
                                                    name: addStatusName.trim(),
                                                    color: addStatusColor
                                                },
                                                ...x
                                            ]
                                        })
                                        setAddStatusName('')
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                            <CirclePicker
                                onChangeComplete={e => setAddStatusColor(e.hex)}
                                className='mt-4'
                                color={addStatusColor}
                            />
                        </CardContent>
                    </Card>
                    {/* End of statuses */}
                    {/* Catatan */}
                    <TextField 
                        multiline
                        label='Catatan'
                        size='small'
                        className='mt-4'
                        fullWidth
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        minRows={4}
                        maxRows={5}
                        disabled={loading}
                    />
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button variant='contained'
                    disabled={loading}
                    onClick={add}
                >Tambah</Button>
            </DialogActions>
        </Dialog>
    )
}