import { IconButton, Tooltip, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Cookies from "universal-cookie";
import axios from "axios";
import global from "../../Global";

const cookies = new Cookies();
export default function LaporkanPendapatan() {
  const [loading, setLoading] = useState(false);

  function handleSend() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/lapor-statistik-harian",
    })
      .then((res) => {
        setLoading(false);
        window.alert(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  return (
    <>
      {!loading && (
        <Tooltip title="Laporkan pendapatan hari ini">
          <IconButton
            onClick={handleSend}
            sx={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            <AssessmentIcon />
          </IconButton>
        </Tooltip>
      )}

      {loading && (
        <CircularProgress
          size="30px"
          sx={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        />
      )}
    </>
  );
}
