import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import global from "../../Global";

const cookies = new Cookies();

export default function Admin({ onAdmins }) {
  const [admins, setAdmins] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    onAdmins && onAdmins(selecteds);
  }, [selecteds]);

  useEffect(() => {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/admin-list",
    })
      .then((response) => {
        const res = response.data;
        if (res.error) {
          window.alert(`Server is returned error message: ${res.message}`);
        } else {
          setAdmins(res.data);
          setSelecteds(res.data);
        }
      })
      .catch((err) => {
        window.alert(`Failed for parse admin data: ${err.message}`);
      });
  }, []);

  return (
    <div className="admin_select">
      {admins.map((admin, index) => (
        <AdminItem
          data={admin}
          onClick={(data) => {
            var _selecteds = [...selecteds];
            if (_selecteds.indexOf(data) >= 0) {
              _selecteds.splice(_selecteds.indexOf(data), 1);
            } else {
              _selecteds.push(data);
            }
            setSelecteds(_selecteds);
          }}
          active={selecteds.indexOf(admin) >= 0}
          key={index}
          className="admin"
        />
      ))}
    </div>
  );
}

function AdminItem({ data, onClick, active, ...props }) {
  return (
    <Chip
      label={data.name}
      {...props}
      variant={active ? "" : "outlined"}
      onClick={() => onClick(data)}
      color="primary"
    />
  );
}
