import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  IconButton,
  Card,
  Paper,
  Dialog,
  Badge,
  Tooltip,
  Divider,
  ListItem,
  ListItemText,
  Button,
  CardActionArea,
  CardActions,
  CircularProgress,
} from "@mui/material";
import NotificationIcon from "@mui/icons-material/Notifications";
import NotificationIconOutlined from "@mui/icons-material/NotificationsOutlined";
import "./Notification.scss";
import moment from "moment-timezone";
import EmptyImage from "../../Static/Images/Empty-amico.png";

const cookies = new Cookies();
export default function Notification({ global }) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  return (
    <div className="notification">
      <Toggle global={global} opened={open} onToggle={handleToggle} />
      <NotificationCard global={global} opened={open} />
    </div>
  );
}

function NotificationCard({ global, opened }) {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const handleClear = () => {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/clear-notification",
    })
      .then((response) => {
        getNotifications();
      })
      .catch((err) => {
        handleClear();
      });
  };

  const getNotifications = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/notifications",
      data: {
        session: cookies.get("session"),
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        setNotifications(res);
      })
      .catch((err) => {
        getNotifications();
      });
  };

  useEffect(() => {
    if (opened) {
      getNotifications();
    }
  }, [opened]);

  return (
    opened && (
      <Card className="notification-card">
        <ListItem className="notification-title">Notifications</ListItem>
        <Divider />
        {!loading && (
          <div className="items">
            {notifications.length < 1 && (
              <img src={EmptyImage} alt="Empty" className="empty" />
            )}
            {notifications.map((notification, index) => (
              <>
                <ListItem className="item">
                  <div className="left">
                    <div className="title">{notification.title}</div>
                    <div className="message">{notification.message}</div>
                  </div>
                  <div className="right">
                    <div className="moment">
                      {moment(notification.created * 1000)
                        .tz("Asia/Jakarta")
                        .fromNow()}
                    </div>
                  </div>
                </ListItem>
                <Divider />
              </>
            ))}
          </div>
        )}
        {loading && (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        <CardActions>
          <Button
            size="small"
            sx={{ display: "flex", marginLeft: "auto", marginRight: 0 }}
            onClick={handleClear}
          >
            Clear
          </Button>
        </CardActions>
      </Card>
    )
  );
}

function Toggle({ global, onToggle, opened }) {
  const [count, setCount] = useState(0);

  const getCounter = () => {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/notification-counter",
    })
      .then((response) => {
        const count = response.data.count;
        setCount(count);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCounter();
    const interval = setInterval(getCounter, 25000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Tooltip title="Notifications">
      <IconButton
        sx={{
          mr: 1,
          background: opened ? "rgba(51, 103, 214, .1) !important" : "#fff",
        }}
        onClick={onToggle}
      >
        <Badge badgeContent={count} color="primary" max={99}>
          {!opened && <NotificationIcon />}
          {opened && (
            <NotificationIconOutlined
              sx={{ color: "rgba(51, 103, 214, .7) !important" }}
            />
          )}
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
