import { useEffect, useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import "./AccountPerformance.scss";
import Data from "./Data";

const cookies = new Cookies();
export default function AccountPerformance({ global }) {
  const [lastWeek, setLastWeek] = useState(4);
  const [accounts, setAccounts] = useState([]);

  function getAccounts() {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/stores",
    })
      .then((response) => {
        const data = response.data;
        setAccounts(data);
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <div className="account-performance">
      {/* <FormControl className="select-last-week">
        <InputLabel id="jangka-waktu">Jangka waktu</InputLabel>
        <Select
          labelId="jangka-waktu"
          label="Jangka waktu"
          value={lastWeek}
          onChange={(e) => setLastWeek(e.target.value)}
        >
          <MenuItem value={1}>1 Minggu terakhir</MenuItem>
          <MenuItem value={2}>2 Minggu terakhir</MenuItem>
          <MenuItem value={3}>3 Minggu terakhir</MenuItem>
          <MenuItem value={4}>4 Minggu terakhir</MenuItem>
          <MenuItem value={5}>5 Minggu terakhir</MenuItem>
          <MenuItem value={6}>6 Minggu terakhir</MenuItem>
          <MenuItem value={7}>7 Minggu terakhir</MenuItem>
          <MenuItem value={8}>8 Minggu terakhir</MenuItem>
          <MenuItem value={9}>9 Minggu terakhir</MenuItem>
          <MenuItem value={10}>10 Minggu terakhir</MenuItem>
        </Select>
      </FormControl> */}
      <Data global={global} lastWeek={lastWeek} accounts={accounts} />
    </div>
  );
}
