import "./Order.scss";
import Data from "./Data";
import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Nothing from "./Nothing";
import Loading from "./Loading";
import Add from "./Add";
import { TextField } from "@mui/material";
import RegardBonus from "./RegardBonus";

var _y;

const cookies = new Cookies();
export default function Order({ global }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regardOpen, setRegardOpen] = useState(false);
  const handleRegardClose = () => setRegardOpen(false);
  const [regardBonus, setRegardBonus] = useState(0);
  const [search, setSearch] = useState("");

  function loadData(props = {}) {
    if (props.with_reload) setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        search: search,
      },
      url: global.api.base + "/main/order-data",
    })
      .then((response) => {
        setLoading(false);
        setData(response.data.pendingOrders);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadData({
      with_reload: true,
    });
  }, []);

  return (
    <div className="order">
      <RegardBonus
        open={regardOpen}
        onClose={handleRegardClose}
        bonus={regardBonus}
      />
      <Add global={global} onChange={loadData} />
      <TextField
        variant="standard"
        label="Pencarian"
        sx={{ position: "absolute", top: "10px", right: "10px" }}
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={(e) => {
          if (_y) clearTimeout(_y);

          _y = setTimeout(() => {
            loadData();
          }, 500);
        }}
      />
      {loading && <Loading />}
      {!loading && (
        <>
          {data.length < 1 && <Nothing />}
          {data.length > 0 && (
            <>
              <Data
                onBonus={(b) => {
                  setRegardBonus(b);
                  setRegardOpen(true);
                }}
                global={global}
                onChange={() => {
                  loadData();
                }}
                data={data}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
