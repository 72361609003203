import * as React from "react";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Cookies from "universal-cookie";
import { CircularProgress, Card } from "@mui/material";
import Action from "./Action";
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
var typingTimer;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

const cookies = new Cookies();

export default function Data({ global, onChange, loading, data }) {
  const columns = [
    {
      field: "i",
      headerName: "ID",
      width: 70,
      renderCell: (params) => <div>{params.value + 1}</div>,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
    },
    {
      field: "name",
      headerName: "Nama Toko",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "password",
      headerName: "Password",
      width: 200,
      editable: true,
    },
    {
      field: "reputasi",
      headerName: "Reputasi",
      width: 130,
      editable: true,
    },
    {
      field: "tanggalUpload",
      headerName: "Tanggal Upload",
      width: 150,
      editable: true,
    },
    {
      field: "jumlahProduk",
      headerName: "Jumlah Produk",
      width: 100,
      editable: true,
    },
    {
      field: "market",
      headerName: "Market",
      width: 130,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      editable: true,
    },
    {
      field: "authen",
      headerName: "Authen",
      width: 350,
      editable: true,
    },
    {
      field: "pemilik",
      headerName: "Pemilik",
      width: 200,
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Action
          params={params}
          onDeleted={(id) => {
            const r = [...rows];
            const i = r.findIndex((x) => x.id == id);
            if (i >= 0) {
              r.splice(i, 1);
            }
            setRows(r);
          }}
        />
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [changeLoading, setChangeLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);

  const [searchText, setSearchText] = React.useState("");

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setFilteredRows(filteredRows);
  };

  useEffect(() => {
    requestSearch(searchText);
  }, [rows, searchText]);

  useEffect(() => {
    var rows = data.map((x, i) => {
      return {
        id: x.ai,
        i: i,
        name: x.name,
        email: x.email,
        password: x.password,
        reputasi: x.reputasi,
        tanggalUpload: x.tanggal_upload,
        jumlahProduk: x.jumlah_produk,
        market: x.market,
        status: x.status,
        authen: x.authen,
        action: x.ai,
        pemilik: x.pemilik,
      };
    });
    setRows(rows);
  }, [data]);

  const handleUpdate = (newVal, oldVal) => {
    setChangeLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/update-akun",
      data: {
        session: cookies.get("session"),
        ...newVal,
      },
    })
      .then((response) => {
        setChangeLoading(false);
        const res = response.data;

        if (res.error) {
          window.alert(res.message);
        }
      })
      .catch((err) => {
        setChangeLoading(false);
        const r = [...rows];
        r[oldVal.i] = oldVal;
        setRows(r);

        window.alert(err.message);
      });
  };

  return (
    <>
      {changeLoading && (
        <Card
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </Card>
      )}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box sx={{ height: "calc(100vh - 100px)", width: "100%" }}>
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={filteredRows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={handleUpdate}
            componentsProps={{
              toolbar: {
                onChange: (event) => {
                  if (typingTimer) {
                    clearTimeout(typingTimer);
                  }

                  typingTimer = setTimeout(() => {
                    setSearchText(event.target.value);
                  }, 600);
                },
                clearSearch: () => requestSearch(""),
              },
            }}
          />
        </Box>
      </Paper>
    </>
  );
}
