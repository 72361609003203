import { CircularProgress, Card } from "@mui/material";

export default function Loading() {
  return (
    <div className="loading">
      <CircularProgress className="loading-bar" />
      <div className="title">Tunggu sebentar ...</div>
    </div>
  );
}
