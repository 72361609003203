import MainImage from "../../Static/Images/ThanksAmico.png";
import moment from "moment-timezone";
import "moment/locale/id";

export default function AlreadyReported() {
  return (
    <div className="already-reported">
      <img src={MainImage} alt="Already Reported" className="main-image" />
      <div className="title">Laporan telah dikirim untuk hari ini</div>
      <div className="message">
        Laporan pada {moment().format("dddd, D/M/YYYY")} telah dikirim, dan good
        job.
      </div>
    </div>
  );
}
