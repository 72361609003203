import { Card, CardContent } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import AlreadyReported from "./AlreadyReported";
import "./LaporanHarian.scss";
import ReportForm from "./ReportForm";

const cookies = new Cookies();
export default function LaporanHarian({ global }) {
  const [reported, setReported] = useState(true);
  const [reportData, setReportData] = useState(undefined);

  function getAlreadyReported() {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/laporan-harian-check",
    })
      .then((response) => {
        const res = response.data;
        setReported(res.already_submitted);
        setReportData(res.data);
      })
      .catch((err) => {
        window.alert(`Failed for get the daily submitted data: ${err.message}`);
      });
  }

  useEffect(() => {
    getAlreadyReported();
  }, []);

  return (
    <Card className="laporan-harian">
      <CardContent>
        {reported && <AlreadyReported />}
        {!reported && (
          <ReportForm
            global={global}
            onReported={() => {
              setReported(true);
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}
