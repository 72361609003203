import {
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
  Avatar,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import template from "../../Static/Xlsx/knine_upload-template.xlsx";
import axios from "axios";
import Cookies from "universal-cookie";
import CompleteImage from "../../Static/Images/Completed-pana.png";
import CloseIcon from "@mui/icons-material/Close";

const cookies = new Cookies();
export default function BulkAdd({ global, onAdded }) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);

  return (
    <div className="bulk-add" style={{ padding: 0, margin: 0 }}>
      <Button
        variant="contained"
        size="small"
        className="btn-add"
        onClick={() => setModalOpen(true)}
      >
        Bulk Add
      </Button>
      <BulkModal
        global={global}
        onClose={handleModalClose}
        open={modalOpen}
        onUploaded={onAdded}
      />
    </div>
  );
}

function BulkModal({ global, onClose, open, onUploaded }) {
  function handleDownloadTemplate() {
    window.open(template, "_blank");
  }

  const [file, setFile] = useState([]);
  const [ready4upload, setReady4upload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(12);
  const [revisiUrl, setRevisiUrl] = useState(undefined);
  const [revisiError, setRevisiError] = useState(0);
  const [revisiSuccess, setRevisiSuccess] = useState(0);

  function handleUpload() {
    const formData = new FormData();
    setProgress(0);
    formData.append("knine_sellerexcel", file[0]);
    setLoading(true);

    axios
      .post(
        global.api.base +
          "/main/bulk-add-toko?session=" +
          cookies.get("session"),
        formData,
        {
          data: {
            session: cookies.get("session"),
          },
          timeout: 10000000,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: ({ loaded, total }) => {
            setProgress((loaded / total) * 100);
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          download(
            "KNine Bulk Result.txt",
            `${response.data.message}. \n ERRORS: ${response.data.errors.map(
              (x) => "*" + x + "\n"
            )}`
          );
          setRevisiUrl("#");
        } else {
          onUploaded();
          setRevisiUrl(global.api.base + response.data.file.revisi);
          setRevisiError(response.data.data.error);
          setRevisiSuccess(response.data.data.success);
        }
        setRevisiError(response.data.data.error);
        setRevisiSuccess(response.data.data.success);
        setLoading(false);
        setProgress(0);
      })
      .catch((err) => {
        console.log(err);
        window.alert(err);
        setLoading(false);
        setProgress(0);
      });
  }

  function download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  useEffect(() => {
    if (file.length <= 0) {
      setReady4upload(false);
    } else {
      setReady4upload(true);
    }
  }, [file]);

  return (
    <Dialog open={open} onClose={onClose}>
      {loading && (
        <LinearProgress
          variant={progress >= 100 ? undefined : "determinate"}
          value={progress >= 100 ? undefined : progress}
        />
      )}
      <DialogContent>
        {revisiUrl === undefined && (
          <div className="bulk-modal">
            <div className="left">
              <div className="title">
                <Avatar
                  sx={{ width: "25px", height: "25px", background: "#3367D6" }}
                >
                  1
                </Avatar>
                <span>Download & isi file Excel</span>
              </div>
              <div className="body">
                <Button variant="outlined" onClick={handleDownloadTemplate}>
                  Download Template
                </Button>
              </div>
            </div>
            <div className="right">
              <div className="title">
                <Avatar
                  sx={{ width: "25px", height: "25px", background: "#3367D6" }}
                >
                  2
                </Avatar>
                <span>Upload file Excel</span>
              </div>
              <div className="body" style={{ display: "flex" }}>
                <input
                  disabled={loading}
                  type={"file"}
                  onChange={(e) => setFile(e.target.files)}
                />
                {ready4upload && (
                  <Button
                    onClick={handleUpload}
                    disabled={loading}
                    size="small"
                    variant="contained"
                  >
                    Upload
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        {revisiUrl && (
          <div className="revisi">
            <IconButton
              className="close"
              onClick={() => {
                setRevisiUrl(undefined);
              }}
            >
              <CloseIcon />
            </IconButton>
            <img className="success-img" src={CompleteImage} alt="Success" />
            <div className="left">{revisiError} Error</div>
            <div className="right">{revisiSuccess} Success</div>
            {revisiUrl !== "#" && (
              <Button
                size="small"
                variant="outlined"
                sx={{ margin: "auto", display: "flex", mt: 1 }}
                onClick={() => {
                  window.open(revisiUrl, "_blank");
                }}
              >
                Download revisi file
              </Button>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
