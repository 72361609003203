import { useEffect, useState } from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import axios from "axios";
import Global from "../../Global";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function Avatar({ avatar, uid, onChange, ...props }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  function uploadAvatar(base) {
    setLoading(true);
    axios({
      method: "POST",
      url: Global.api.base + "/main/upload-avatar",
      data: {
        base,
        session: cookies.get("session"),
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          onChange();
        }
      })
      .catch((err) => {
        window.alert(err.message);
        setLoading(false);
      });
  }

  function handleFileChange(e) {
    try {
      const reader = new FileReader();

      reader.readAsBinaryString(e.target.files[0]);
      reader.onloadend = () => {
        const base = btoa(reader.result);
        console.log(base);
        uploadAvatar(base);
      };
    } catch (err) {
      setFile(null);
    }
  }

  return (
    <div className="avatar-container" {...props}>
      <div className="first-flex">
        {avatar && (
          <img
            className="avatar"
            src={avatar ? Global.api.base + "/avatar/" + uid + "?w=40" : "#"}
            draggable={false}
            alt="knine"
            title="knine_admin"
            loading="lazy"
            width="40"
            height="40"
            style={{ borderRadius: "4px", marginRight: "10px" }}
          />
        )}
        <input
          onChange={handleFileChange}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          disabled={loading}
        />
        {loading && <CircularProgress size="small" sx={{ width: "20px;" }} />}
      </div>
    </div>
  );
}
