//  Load all account
import axios from "axios";
import Cookies from "universal-cookie";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import EmptyImage from "../../Static/Images/Empty-amico.png";
import Admin from "./Admin";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment-timezone";

const cookies = new Cookies();
export default function PembukuanBulanan({ global }) {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(dayjs());

  function loadAccount() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/keuangan-admin-username",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          setAdmins(res.uids);
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    loadAccount();
  }, [currentDateTime]);

  return (
    <div className="pembukuan-bulanan">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          size="small"
          views={["year", "month"]}
          label="Pilih bulan"
          onChange={(newValue) => {
            setCurrentDateTime(newValue);
          }}
          value={currentDateTime}
          renderInput={(params) => (
            <TextField
              sx={{ mt: 3, ml: 3 }}
              size="small"
              {...params}
              helperText={null}
            />
          )}
        />
      </LocalizationProvider>

      {loading && (
        <div className="loading">
          <CircularProgress className="bar" />
          <div className="title">Tunggu sebentar</div>
          <div className="subtitle">Sedang menyiapkan ...</div>
        </div>
      )}

      {!loading && admins.length < 1 && (
        <div className="empty">
          <img src={EmptyImage} alt="empty" title="empty" className="image" />
        </div>
      )}

      {!loading && admins.length > 0 && (
        <div className="admins">
          {admins.map((admin, index) => (
            <Admin
              global={global}
              className="admin"
              uid={admin.uid}
              name={admin.name}
              key={index}
              datetime={currentDateTime}
            />
          ))}
        </div>
      )}
    </div>
  );
}
