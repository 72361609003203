import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import "moment/locale/id";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useRef } from "react";
import Download from "./Download";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Graph({ data, epoch, ...props }) {
  const graphRef = useRef();
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalModal, setTotalModal] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);

  useEffect(() => {
    // Prepare total data
    const d = [...data];
    var p = 0;
    var m = 0;
    var o = 0;

    for (var r of d) {
      p += r.profit;
      m += r.modal;
      o += r.order;
    }

    setTotalProfit(p);
    setTotalModal(m);
    setTotalOrder(o);
  }, []);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Grafik - " + moment(epoch * 1000).format("MMMM YYYY"),
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const labels = data.map((x) => x.date);
  const d = {
    labels,
    datasets: [
      {
        label: `Profit [${formatRupiah(totalProfit.toString())}]`,
        data: data.map((x) => x.profit),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(255, 99, 132)",
        yAxisID: "y",
      },
      {
        label: `Modal [${formatRupiah(totalModal.toString())}]`,
        data: data.map((x) => x.modal),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgb(53, 162, 235)",
        yAxisID: "y1",
      },
      {
        label: `Finish order [${formatRupiah(totalOrder.toString(), "").replace(
          "Rp. ",
          ""
        )}]`,
        data: data.map((x) => x.order),
        borderColor: "green",
        backgroundColor: "green",
        yAxisID: "y1",
      },
    ],
  };

  return (
    <Card {...props}>
      <Download epoch={epoch} el={graphRef} />
      <Line options={options} ref={graphRef} className="line" data={d} />
    </Card>
  );
}

function formatRupiah(angka, prefix) {
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
