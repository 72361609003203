import { CircularProgress } from "@mui/material";

export default function Loading() {
  return (
    <div className="loading">
      <CircularProgress className="bar" />
      <div className="loading-title">Tunggu sebentar ...</div>
    </div>
  );
}
