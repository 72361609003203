import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  IconButton,
  Button,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import "./Pendapatan.scss";
import global from "../../Global";

const cookies = new Cookies();
export default function PendapatanSimple() {
  const [bonus, setBonus] = useState(0);

  function loadBonus() {
    setBonus(0);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/pendapatan",
    })
      .then((response) => {
        const res = response.data;
        const i = res.data.findIndex((x) => x.name == "Bonus Profit");
        if (i >= 0) {
          setBonus(res.data[i].value);
        }
      })
      .catch(async (err) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        loadBonus();
      });
  }

  useEffect(() => {
    loadBonus();
  }, []);

  return (
    <>
      <div className="Bonus">Bonus: {formatRupiah(bonus, "Rp ")}</div>
      <Tooltip title="Refresh bonus">
        <IconButton
          onClick={loadBonus}
          sx={{
            mr: 1,
          }}
        >
          <MonetizationOnIcon sx={{ color: "#ECA800" }} />
        </IconButton>
      </Tooltip>
    </>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
