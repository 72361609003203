import {
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  List,
  ListItem,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "./ShareProfit.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "./Loading";
import NothingFound from "./NothingFound";
import DataDialog from "./DataDialog";
import Cookies from "universal-cookie";
import Data from "./Data";

const cookies = new Cookies();
export default function ShareProfit({ global }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [dialogAi, setDialogAi] = useState(undefined);

  const handleOpenDataDialog = () => setDataDialogOpen(true);
  const handleCloseDataDialog = () => setDataDialogOpen(false);

  function handleOpenData(ai) {
    setDialogAi(ai);
    setDataDialogOpen(true);
  }

  function loadData() {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/share-profit-list",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          setData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  function handleDelete(ai) {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        ai,
      },
      url: global.api.base + "/main/delete-share-profit",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          loadData();
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  return (
    <div className="share-profit">
      <DataDialog
        global={global}
        onClose={handleCloseDataDialog}
        open={dataDialogOpen}
        ai={dialogAi}
        onChange={loadData}
      />
      {loading && <Loading />}
      {!loading && data.length < 1 && (
        <NothingFound
          onAdd={() => {
            setDialogAi(null);
            handleOpenDataDialog();
          }}
        />
      )}
      {!loading && data.length > 0 && (
        <Data
          onAddRequest={() => {
            setDialogAi(null);
            setDataDialogOpen(true);
          }}
          onOpen={handleOpenData}
          onDelete={handleDelete}
          data={data}
        />
      )}
    </div>
  );
}
