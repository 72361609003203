import "./GraphIncome.scss";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment-timezone";
import axios from "axios";
import Cookies from "universal-cookie";
import Loading from "./Loading";
import Graph from "./Graph";
import Admins from "./Admin";
import dayjs from "dayjs";

const cookies = new Cookies();
export default function GraphIncome({ global }) {
  const [currentDateTime, setCurrentDateTime] = useState(dayjs());
  const [epoch, setEpoch] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selected_admins, set_selected_admins] = useState([]);

  useEffect(() => {
    if (epoch > 0) {
      loadData({
        admins: selected_admins.map((x) => x.uid),
      });
    }
  }, [epoch, selected_admins]);

  useEffect(() => {
    setEpoch(moment(currentDateTime.$d).unix());
  }, [currentDateTime]);

  function loadData(props) {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        bulan: parseInt(moment(epoch * 1000).format("M")),
        tahun: parseInt(moment(epoch * 1000).format("YYYY")),
        ...props,
      },
      url: global.api.base + "/main/income-graph",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.error) {
          console.error(res.message);
        }
        setData(res);
      })
      .catch((err) => {
        console.error(err.message);
        setLoading(false);
      });
  }

  return (
    <div className="graph_income">
      {loading && <Loading />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          size="small"
          views={["year", "month"]}
          label="Pilih bulan"
          onChange={(newValue) => {
            setCurrentDateTime(newValue);
          }}
          value={currentDateTime}
          renderInput={(params) => (
            <TextField
              sx={{ mt: 3, ml: 3 }}
              size="small"
              {...params}
              helperText={null}
            />
          )}
        />
      </LocalizationProvider>
      <Admins onAdmins={(admins) => set_selected_admins(admins)} />
      {!loading && <Graph data={data} epoch={epoch} className="graph" />}
    </div>
  );
}
