import {
  Card,
  CardContent,
  CardActions,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";

export default function LoginCard() {
  function handleLogin() {
    window.location.href = "/auth";
  }

  return (
    <Card
      className="login_card"
      color="primary"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Tooltip title="Login sebagai admin">
        <IconButton onClick={handleLogin} size="large">
          <AccountCircleTwoToneIcon sx={{ fontSize: "30px" }} />
        </IconButton>
      </Tooltip>
    </Card>
  );
}
