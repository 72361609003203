import {
  IconButton,
  Button,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  LinearProgress,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Autocomplete,
} from "@mui/material";
import moment from "moment-timezone";
import "moment/locale/id";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import DynamicAddUrls from "../../Component/DynamicAddUrls";

const cookies = new Cookies();
export default function Data({ global, data, onChange, onBonus }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarCopyOpen = () => setSnackbarOpen(true);
  const handleSnackbarCopyClose = () => setSnackbarOpen(false);

  const [totalModal, setTotalModal] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalOmset, setTotalOmset] = useState(0);
  const [reportOpen, setReportOpen] = useState(false);

  const [reportLoading, setReportLoading] = useState(false);
  const [reportDisabled, setReportDisabled] = useState(false);

  function handleOpenReport() {
    setReportOpen(true);
  }

  function handleReport(pendingOrder) {
    setReportLoading(true);
    setReportDisabled(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        pendingOrder,
      },
      url: global.api.base + "/main/lapor-statistik-harian",
    })
      .then((response) => {
        const res = response.data;
        setReportLoading(false);
        if (res.error) {
          window.alert(res.message);
        } else {
          setReportDisabled(true);
        }
      })
      .catch((err) => {
        setReportLoading(false);
        console.error(err);
      });
  }

  function loadTotalData() {
    var hm = 0;
    var hj = 0;
    for (var row of data) {
      hm += row.harga_modal;
      hj += row.harga_jual;
    }

    setTotalModal(hm);
    setTotalOmset(hj);
    setTotalProfit(hj - hm);
  }

  function loadReportedState() {
    setReportDisabled(true);
    setReportLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/laporan-harian-check",
    })
      .then((response) => {
        setReportLoading(false);
        const res = response.data;
        setReportDisabled(res.already_submitted);
      })
      .catch((err) => {
        setReportLoading(false);
        setReportDisabled(true);
        console.error(err.message);
      });
  }

  useEffect(() => {
    loadReportedState();
  }, []);

  useEffect(() => {
    loadTotalData();
  }, [data]);

  return (
    <div className="data">
      <ReportDialog
        open={reportOpen}
        onClose={() => {
          setReportOpen(false);
        }}
        onSubmit={(pendingOrder) => {
          handleReport(pendingOrder);
        }}
      />
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarCopyClose}
        autoHide
        autoHideDuration={6000}
      >
        <Alert
          onClose={handleSnackbarCopyClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Invoice berhasil disalin ke clipboard
        </Alert>
      </Snackbar>

      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className="nowrap" disablePadding>
                #
              </TableCell>
              <TableCell className="nowrap" sx={{ minWidth: 50 }}>
                Invoice
              </TableCell>
              <TableCell className="nowrap">Produk</TableCell>
              <TableCell className="nowrap">Toko</TableCell>
              <TableCell className="nowrap">Pembeli</TableCell>
              <TableCell className="nowrap">Harga Jual</TableCell>
              <TableCell className="nowrap">Harga Modal</TableCell>
              <TableCell className="nowrap">Profit</TableCell>
              <TableCell className="nowrap">Pada</TableCell>
              <TableCell className="nowrap"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => {
              const profit = row.harga_jual - row.harga_modal;
              return (
                <TableRow key={i} className="row" id="data-row">
                  <TableCell disablePadding sx={{ width: "10px !important" }}>
                    {i + 1}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      copyToClipboard(row.kode_invoice, () => {
                        setSnackbarOpen(true);
                      });
                    }}
                    className="copy-clipboard"
                    sx={{
                      maxWidth: 300,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Tooltip title="Salin ke clipboard">
                      <span>{row.kode_invoice}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="product">
                    <div className="name">{row.produk}x</div>
                    <div
                      className="urls"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {row.url_produk.split("$$$$").map((url) => (
                        <a
                          className="url"
                          href={url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {url.length > 35 ? url.slice(0, 35) + "..." : url}
                        </a>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>{row.toko}</TableCell>
                  <TableCell>{row.pembeli}</TableCell>
                  <TableCell>{formatRupiah(row.harga_jual, "Rp ")}</TableCell>
                  <TableCell>{formatRupiah(row.harga_modal, "Rp ")}</TableCell>
                  <TableCell
                    sx={{
                      color: profit < 0 ? "red" : "#000",
                    }}
                  >
                    {profit < 0 ? "- " : ""}
                    {formatRupiah(profit, "Rp ")}
                  </TableCell>
                  <TableCell className="date" sx={{ maxWidth: 120 }}>
                    <div className="date-format">
                      {moment(row.created * 1000)
                        .tz("Asia/Jakarta")
                        .format("DD/MM/YYYY")}
                    </div>
                    <div className="ago">
                      {moment(row.created * 1000)
                        .tz("Asia/Jakarta")
                        .fromNow()}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Action
                      oid={row.oid}
                      onChanged={onChange}
                      global={global}
                      onBonus={onBonus}
                      onHide={() => {
                        // const d = document.querySelectorAll("#data-row");
                        // console.log((d[i].style = "display: none"));
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableRow sx={{ backgroundColor: "#AEE6F7" }}>
            <TableCell sx={{ fontWeight: "500", fontSize: "17px" }} colSpan={2}>
              SUB TOTAL
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell sx={{ padding: 0 }} align="center">
              Omset:
              <br />
              {formatRupiah(totalOmset.toFixed(0), "Rp ")}
            </TableCell>
            <TableCell sx={{ padding: 0 }} align="center">
              Modal:
              <br />
              {formatRupiah(totalModal.toFixed(0), "Rp ")}
            </TableCell>
            <TableCell></TableCell>

            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{ padding: 0 }}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              colspan={2}
              sx={{
                backgroundColor: "#225782",
                fontSize: "18px",
                color: "#fff",
                fontWeight: "600",
              }}
            >
              {formatRupiah(totalProfit.toFixed(0), "Rp ")}
            </TableCell>
            <TableCell align="right">
              {reportLoading && <LinearProgress />}
              <Button
                disabled={reportDisabled}
                onClick={handleOpenReport}
                startIcon={<SendIcon />}
              >
                Kirim Laporan
              </Button>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
}

function ReportDialog({ open, onClose, onSubmit }) {
  const [valid, setValid] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    var reg = new RegExp("^[0-9]+$");
    setValid(reg.test(value));
  }, [value]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        className="report"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <TextField
          size="small"
          variant="outlined"
          label="Pending order"
          type="number"
          value={value}
          onChange={(E) => {
            setValue(E.target.value);
          }}
        />
        <IconButton
          onClick={() => {
            onSubmit(parseInt(value));
            onClose();
          }}
          disabled={!valid}
          sx={{ ml: 1 }}
          variant="contained"
        >
          <SendIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

function Action({ oid, global, onChanged, onBonus, onHide }) {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [hargaJual, setHargaJual] = useState(0);
  const [hargaModal, setHargaModal] = useState(0);
  const [pembeli, setPembeli] = useState("");
  const [produk, setProduk] = useState(1);
  const [urlProduk, setUrlProduk] = useState([]);
  const [profit, setProfit] = useState(0);
  const [toko, setToko] = useState("");

  const [blockUpdate, setBlockUpdate] = useState(false);

  useEffect(() => {
    if (!parseInt(onlyNumber(hargaJual))) {
      setBlockUpdate(true);
    } else if (!parseInt(onlyNumber(hargaModal))) {
      setBlockUpdate(true);
    } else if (pembeli.length < 3) {
      setBlockUpdate(true);
    } else if (produk < 1) {
      setBlockUpdate(true);
    } else if (urlProduk.length < 1) {
      setBlockUpdate(true);
    } else if (toko == null || toko.length < 1) {
      setBlockUpdate(true);
    } else {
      setBlockUpdate(false);
    }
  }, [hargaJual, hargaModal, pembeli, produk, urlProduk, profit, toko]);

  useEffect(() => {
    setProfit(hargaJual - hargaModal);
  }, [hargaJual, hargaModal]);

  function handleEditShow(oid) {
    setEditShow(true);
    setEditLoading(true);

    axios({
      method: "POST",
      data: {
        oid,
      },
      url: global.api.base + "/main/pending-order-data",
    })
      .then((response) => {
        const res = response.data.data;
        setHargaJual(res.harga_jual);
        setHargaModal(res.harga_modal);
        setPembeli(res.pembeli);
        setProduk(parseInt(onlyNumber(res.produk)));
        setUrlProduk(res.url_produk.split("$$$$"));
        setToko(res.toko);
        // Get the toko
        axios({
          method: "POST",
          data: {
            session: cookies.get("session"),
          },
          url: global.api.base + "/main/stores",
        })
          .then((response) => {
            setStores(response.data);
            setEditLoading(false);
          })
          .catch((err) => {
            setEditLoading(false);
            window.alert("failed for get stores: " + err.message);
          });
      })
      .catch((err) => {
        setEditLoading(false);
        window.alert(err.message);
      });
  }

  const handleAction = (act) => {
    setLoading(true);
    if (
      !window.confirm(
        `Apakah anda yakin ingin ${
          act === "DONE" ? "menyelesaikan" : "membatalkan"
        } orderan ini ? aksi yang anda pilih tidak dapat dikembalikan, pastikan bahwa pilihan anda benar`
      )
    ) {
      return setLoading(false);
    }

    // Request untuk melakuakn perubahan pada order tersebut
    axios({
      method: "POST",
      url: global.api.base + "/main/change-order-pending-state",
      data: {
        oid,
        act,
        session: cookies.get("session"),
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        if (res.bonus) {
          onBonus(res.bonus);
        }
        if (res.error) {
          window.alert(res.message);
        } else {
          setLoading(false);
          onHide();
          onChanged();
        }
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  };

  const handleChangeHargaJual = (e) => {
    try {
      setHargaJual(parseInt(onlyNumber(e.target.value)) || 0);
    } catch (er) {
      setHargaJual(0);
    }
  };

  const handleChangeHargaModal = (e) => {
    try {
      setHargaModal(parseInt(onlyNumber(e.target.value)) || 0);
    } catch (err) {
      setHargaModal(0);
    }
  };

  function handleUpdate() {
    setEditLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        oid,
        hargaJual,
        hargaModal,
        pembeli,
        produk,
        urlProduk,
        toko,
      },
      url: global.api.base + "/main/update-pending-order",
    })
      .then((response) => {
        setEditLoading(false);
        const res = response.data;
        if (res.error) {
          window.alert(res.message);
        } else {
          onChanged();
          setEditShow(false);
        }
      })
      .catch((err) => {
        setEditLoading(false);
        window.alert(err.message);
      });
  }

  return (
    <div className="action">
      {editShow && (
        <Dialog
          open={editShow}
          onClose={
            editLoading
              ? () => {}
              : () => {
                  setEditShow(false);
                }
          }
        >
          {editLoading && <LinearProgress />}
          <DialogContent>
            <FormControl fullWidth sx={{ gap: "10px" }}>
              <TextField
                label="Harga Jual"
                value={formatRupiah(hargaJual, "Rp ")}
                size="small"
                onChange={handleChangeHargaJual}
                error={profit < 0}
                helperText={`Profit: ${profit < 0 ? "- " : ""}${formatRupiah(
                  profit,
                  "Rp "
                )}`}
                disabled={editLoading}
              />
              <TextField
                label="Harga Modal"
                value={formatRupiah(hargaModal, "Rp ")}
                onChange={handleChangeHargaModal}
                size="small"
                disabled={editLoading}
              />
              <TextField
                label="Pembeli"
                value={pembeli}
                onChange={(e) => setPembeli(e.target.value)}
                size="small"
                disabled={editLoading}
              />
              <TextField
                label="Pcs"
                value={produk}
                onChange={(e) => {
                  try {
                    setProduk(parseInt(onlyNumber(e.target.value)) || 0);
                  } catch (err) {
                    setProduk(0);
                  }
                }}
                size="small"
                disabled={editLoading}
                type="number"
              />
              <DynamicAddUrls
                urls={urlProduk}
                onChange={(urls) => setUrlProduk(urls)}
              />
              <Autocomplete
                options={stores}
                clearOnEscape
                fullWidth
                getOptionLabel={(stores) => stores}
                onChange={(e) => {
                  setToko(e.target.textContent);
                }}
                disabled={editLoading}
                renderInput={(params) => (
                  <TextField
                    label="Nama Toko"
                    {...params}
                    size="small"
                    variant="outlined"
                    onChange={(e) => setToko(e.target.value)}
                    value={toko}
                  />
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditShow(false);
              }}
              disabled={editLoading}
            >
              Batal
            </Button>
            <Button
              onClick={handleUpdate}
              disabled={editLoading || blockUpdate}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!loading && (
        <>
          <Button
            onClick={handleAction.bind(this, "CANCEL")}
            size="small"
            className="cancel"
            variant="contained"
          >
            Batal
          </Button>
          <Button
            onClick={handleEditShow.bind(this, oid)}
            size="small"
            className="cancel"
            variant="contained"
            sx={{ background: "green !important" }}
          >
            Edit
          </Button>
          <Button
            onClick={handleAction.bind(this, "DONE")}
            size="small"
            className="accept"
            variant="contained"
          >
            Selesai
          </Button>
        </>
      )}

      {loading && <CircularProgress size="20px" />}
    </div>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

const copyToClipboard = (text, cb = () => {}) => {
  console.log(text);
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  cb();
};

function onlyNumber(str) {
  str = str.toString();
  return str.replace(/[^0-9]/g, "");
}

function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

function capitalizeWord(str) {
  var s = [];
  for (var t of str.split(" ")) {
    try {
      const first = t[0].toUpperCase();
      const letter = t.slice(1);
      s.push(first + letter);
    } catch (e) {
      s.push("");
    }
  }
  return s.join(" ");
}

const validInvoice = (str) => {
  return /(INV\/(?:[0-9]){1,10}\/(?:[A-Z]{1,5})\/(?:[0-9]{1,15}))\w+/g.test(
    str
  );
};
