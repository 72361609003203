import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Divider,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import axios from "axios";
import global from "../../Global";
import BasicDialog from "../../Component/BasicDialog";

export default function AddAccountModal({ open, onClose, onAdded }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("ADMIN");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [errorState, setErrorState] = useState(true);
  const [errorField, setErrorField] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (name.trim().length < 3) {
      setErrorState(true);
      setErrorField("name");
      setErrorMessage("Nama harus setidaknya 3 karakter atau lebih");
    } else if (name.trim().length > 25) {
      setErrorState(true);
      setErrorField("name");
      setErrorMessage("Nama hanya maksimal 25 panjang karakter");
    } else if (!validEmail(email)) {
      setErrorField("email");
      setErrorState(true);
      setErrorMessage("Email tidak valid");
    } else if (password.trim().length < 7) {
      setErrorField("password");
      setErrorMessage("Password harus setidaknya 7 karakter atau lebih");
      setErrorState(true);
    } else if (password.trim().length > 100) {
      setErrorField("password");
      setErrorMessage("Passworwd hanyua maksimal 100 panjang karakter");
      setErrorState(true);
    } else {
      setErrorState(false);
      setErrorMessage("");
      setErrorField("");
    }
  }, [name, email, password, role]);

  const handleChangeName = (e) => setName(e.target.value);
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);
  const handleChangeRole = (e) => setRole(e.target.value);

  const handleGeneratePassword = () => {
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    var char = "";
    const length = 25;
    for (var i = 0; i < length; i++) {
      char += chars[Math.floor(Math.random() * chars.length)];
    }
    setPassword(char);
  };

  function handleAdd() {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/add-admin",
      data: {
        email,
        name,
        password,
        role,
      },
    })
      .then((response) => {
        setDialogMessage(response.data.message);
        setLoading(false);
        setDialogOpen(true);
        if (!response.data.error) {
          onAdded();
        }
      })
      .catch((err) => {
        setLoading(false);
        setDialogMessage(err.message);
        setDialogOpen(true);
      });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <BasicDialog
        open={dialogOpen}
        message={dialogMessage}
        onClose={handleDialogClose}
      />
      <DialogContent className="admin-dialog-add-admin">
        <div className="header">
          <Typography className="title" variant="span">
            Tambahkan admin
          </Typography>
          <IconButton onClick={onClose} size="small" className="close">
            <CloseIcon size="20px" />
          </IconButton>
        </div>
        <Divider className="separator" />
        <div className="form">
          <TextField
            value={name}
            onChange={handleChangeName}
            variant="outlined"
            label="Nama"
            size="small"
            disabled={loading}
            error={errorField === "name"}
            helperText={errorField === "name" ? errorMessage : ""}
          />
          <TextField
            variant="outlined"
            label="Email"
            size="small"
            disabled={loading}
            value={email}
            onChange={handleChangeEmail}
            error={errorField === "email"}
            helperText={errorField === "email" ? errorMessage : ""}
          />
          <div className="password">
            <TextField
              variant="outlined"
              label="Password"
              size="small"
              sx={{ width: "100%" }}
              disabled={loading}
              value={password}
              onChange={handleChangePassword}
              error={errorField === "password"}
              helperText={errorField === "password" ? errorMessage : ""}
            />
            <Tooltip title="Generate password">
              <IconButton
                size="small"
                sx={{ ml: 1 }}
                disabled={loading}
                onClick={handleGeneratePassword}
              >
                <SyncLockIcon />
              </IconButton>
            </Tooltip>
          </div>
          <FormControl size="small" fullWidth>
            <InputLabel id="peran-select-label">Peran</InputLabel>
            <Select
              disabled={loading}
              label="Peran"
              labelId="peran-select-label"
              size="small"
              value={role}
              onChange={handleChangeRole}
            >
              <MenuItem value="ADMIN">Admin</MenuItem>
              <MenuItem value="SUPER_ADMIN">Super Admin</MenuItem>
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        {!loading && <Button onClick={onClose}>Batal</Button>}
        {!loading && (
          <Button onClick={handleAdd} disabled={errorState}>
            Submit
          </Button>
        )}
        {loading && <CircularProgress size="20px" />}
      </DialogActions>
    </Dialog>
  );
}

function validEmail(str) {
  return String(str)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
