import { useEffect, useState } from "react";
import {
  IconButton,
  LinearProgress,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
} from "@mui/material";
import RowData from "./RowData";
import axios from "axios";
import Cookies from "universal-cookie";

let _ROWS = [];
const cookies = new Cookies();
export default function Data({ global, accounts, lastWeek }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  async function loadData() {
    const core = 10;
    setRows([]);
    const distributeAccounts = Distribute(accounts, core);

    for (var d of distributeAccounts) {
      var tasks = [];
      for (var account of d) {
        tasks.push(getSingleData(account));
      }
      await Promise.all(tasks);
    }
  }

  function Distribute(arrays, length) {
    const arrayBatch = Array.from(
      {
        length,
      },
      (v, i) => []
    );
    var i = 0;
    for (var array of arrays) {
      arrayBatch[i % length].push(array);
      i++;
    }
    return arrayBatch;
  }

  function getSingleData(accountName = "") {
    return new Promise((resolve) => {
      axios({
        method: "POST",
        data: {
          name: accountName,
          lastweek: lastWeek,
        },
        url: global.api.base + "/main/account-performance",
        // url: "https://pembukuan.rencangdahar.com/main/account-performance",
      })
        .then((response) => {
          const data = response.data.data;
          var x = {
            namaToko: accountName,
          };
          x.no = 1;
          for (var r of data) {
            x[`week${r.week}ordercount`] = r.data.totalOrder;
            x[`week${r.week}modal`] = r.data.totalModal;
            x[`week${r.week}profit`] = r.data.totalProfit;
          }
          handleAddRow(x);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          resolve();
        });
    });
  }

  function handleAddRow(row) {
    console.log(row);
    setRows((r) => [...r, row]);
  }

  useEffect(() => {
    loadData();
    var s = [
      {
        id: "namaToko",
        label: "Nama Toko",
      },
    ];
    for (var i = 0; i < lastWeek; i++) {
      s.push({
        id: `week${i + 1}ordercount`,
        label: "Jml Order",
      });
      s.push({
        id: `week${i + 1}modal`,
        label: "Modal",
      });
      s.push({
        id: `week${i + 1}profit`,
        label: "Profit",
      });
    }
    setColumns(s);
  }, [accounts, lastWeek]);

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              {Array.from(Array(lastWeek)).map((x, i) => (
                <TableCell colSpan={3}>Minggu ke-{i + 1}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              {columns.map((column, i) => (
                <>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody size="small">
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow
                    size="small"
                    className={i == 0 ? "mt-2" : ""}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          sx={{
                            color:
                              typeof value == "number" && value < 0
                                ? "red"
                                : "",
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {value == 0
                            ? "-"
                            : typeof value == "number"
                            ? `${value < 0 ? "- " : ""}${formatRupiah(
                                value,
                                "Rp "
                              )}`
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}
