import { useParams, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LinearProgress, Alert, TextField, Button } from "@mui/material";
import axios from "axios";

export default function ResetPassword({ global, navigation }) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notifOpen, setNotifOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [errorField, setErrorField] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `Reset password - ${global.appname}`;
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    setErrorField(password !== confirmPassword || password.length < 7);
  }, [password, confirmPassword]);

  const handleReset = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/auth/reset-password",
      data: {
        token,
        password,
      },
    })
      .then((response) => {
        setLoading(false);
        setNotifOpen(true);
        setErrorState(response.data.error);
        setErrorMessage(response.data.message);
        if (!response.data.error) {
          window.alert("Password berhasil diubah");
          window.location.href = "/auth/login";
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifOpen(true);
        setErrorState(true);
        setErrorMessage(err.code);
      });
  };

  return (
    <div className="forgot-password form">
      {(loading || errorField) && (
        <LinearProgress
          sx={{ width: "100%", top: 0, left: 0, position: "absolute" }}
        />
      )}
      <img src={global.icon} className="icon" alt={global.appname} />
      <div className="header">
        <div className="title">Reset password</div>
        <div className="subtitle">Silahkan masukkan password baru anda</div>
      </div>
      {notifOpen && (
        <Alert severity={errorState ? "error" : "success"} sx={{ mt: 1 }}>
          {errorMessage}
        </Alert>
      )}
      <div className="body">
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Password"
          type="password"
          autoFocus
          disabled={loading}
          error={errorField}
          value={password}
          onChange={handlePasswordChange.bind(this)}
        />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Konfirmasi password"
          type="password"
          autoFocus
          disabled={loading}
          value={confirmPassword}
          error={errorField}
          onChange={handleConfirmPassword.bind(this)}
        />
        <div className="actions">
          <Button
            className="right"
            size="small"
            variant="contained"
            disabled={loading || errorField}
            onClick={handleReset.bind(this)}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}
