import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  LinearProgress,
  FormControl,
  FormControlLabel,
  Select,
  ListItem,
  InputLabel,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment-timezone";
import dayjs from 'dayjs'

const cookies = new Cookies();
export default function Add({ global, onAdded }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fors, setFors] = useState([]);
  const [f, setF] = useState("");
  const [email, setEmail] = useState("");
  const [namaToko, setNamaToko] = useState("");
  const [reputasi, setReputasi] = useState("");
  const [tanggalUpload, setTanggalUpload] = useState(dayjs());
  const [jumlahProduk, setJumlahProduk] = useState("");
  const [market, setMarket] = useState("");
  const [status, setStatus] = useState("");
  const [authen, setAuthen] = useState("");
  const [blockAdd, setBlockAdd] = useState(false);
  const [noHp, setNoHp] = useState("");
  const [password, setPassword] = useState("");
  const [ongkir, setOngkir] = useState("");
  const [bank, setBank] = useState("");
  const [rekening, setRekening] = useState("");

  // Event handler
  const handleChangeFor = (e) => {
    setF(e.target.value);
  };

  useEffect(() => {
    if (!validEmail(email)) {
      setBlockAdd(true);
    } else if (namaToko.length < 3 || namaToko.length > 50) {
      setBlockAdd(true);
    } else if (authen.length < 8) {
      setBlockAdd(true);
    } else if (!f.includes("knine_")) {
      setBlockAdd(true);
    } else if (password.length < 3) {
      setBlockAdd(true);
    } else {
      setBlockAdd(false);
    }
  }, [
    email,
    namaToko,
    authen,
    reputasi,
    jumlahProduk,
    market,
    status,
    password,
  ]);

  function getFors() {
    setLoading(true);
    const f = [...fors];
    if (f.length >= 1) {
      setLoading(false);
      return 1;
    }
    axios({
      method: "POST",
      url: global.api.base + "/main/account-fors",
      data: {
        session: cookies.get("session"),
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        setFors(res.data);
        if (typeof res.data[0] != "undefined") {
          setF(res.data[0].uid);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    getFors();
  }, [open]);

  const handleClose = () => (loading ? () => {} : setOpen(false));
  const handleSubmit = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
        namaToko,
        email,
        for: f,
        reputasi,
        tanggalUpload: moment(tanggalUpload)
          .tz("Asia/Jakarta")
          .format("DD/MM/YYYY"),
        jumlahProduk,
        market,
        status,
        authen,
        nohp: noHp,
        password,
        ongkir,
        bank,
        rekening,
      },
      url: global.api.base + "/main/add-seller-account",
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        console.log(res);
        if (res.error) {
          window.alert(res.message);
        } else {
          setOpen(false);
          onAdded();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        window.alert(err.message);
      });
  };

  return (
    <div className="add" style={{ marginTop: "10px" }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add
      </Button>
      <Dialog open={open} onClose={handleClose} sx={{ overflow: "hidden" }}>
        {loading && <LinearProgress sx={{ top: 0, left: 0 }} />}
        <DialogContent className="account-single-add-dialog">
          <div className="forms">
            <FormControl size="small" disabled={loading}>
              <InputLabel id="for-label">Untuk</InputLabel>
              <Select
                onChange={handleChangeFor}
                labelId="for-label"
                label="Untuk"
                value={f}
              >
                {fors.map((f, i) => (
                  <MenuItem value={f.uid} key={i}>
                    {f.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              disabled={loading}
            />
            <TextField
              label="Nama Toko"
              value={namaToko}
              onChange={(e) => setNamaToko(e.target.value)}
              size="small"
              disabled={loading}
            />
            <TextField
              label="Reputasi"
              size="small"
              disabled={loading}
              value={reputasi}
              onChange={(e) => setReputasi(e.target.value)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={tanggalUpload}
                onChange={(v) => {
                  setTanggalUpload(v);
                }}
                disabled={loading}
                label="Tanggal Upload"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <TextField
              value={jumlahProduk}
              onChange={(e) => setJumlahProduk(e.target.value)}
              label="Jumlah Produk"
              size="small"
              type="number"
              disabled={loading}
            />
            <TextField
              label="Market"
              size="small"
              disabled={loading}
              value={market}
              onChange={(e) => setMarket(e.target.value)}
            />
            <TextField
              label="Status"
              size="small"
              disabled={loading}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />
            <TextField
              label="Authen"
              size="small"
              disabled={loading}
              value={authen}
              onChange={(e) => setAuthen(e.target.value)}
            />
            <TextField
              label="No HP"
              size="small"
              type="number"
              disabled={loading}
              value={noHp}
              onChange={(e) => setNoHp(e.target.value)}
            />
            <TextField
              label="Password"
              size="small"
              disabled={loading}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Ongkir"
              size="small"
              disabled={loading}
              value={ongkir}
              onChange={(e) => setOngkir(e.target.value)}
            />
            <TextField
              label="Bank"
              size="small"
              disabled={loading}
              value={bank}
              onChange={(e) => setBank(e.target.value)}
            />
            <TextField
              label="Rekening"
              size="small"
              disabled={loading}
              value={rekening}
              onChange={(e) => setRekening(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Batal
          </Button>
          <Button onClick={handleSubmit} disabled={loading || blockAdd}>
            Tambahkan
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function validEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}
