import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  LinearProgress,
  Divider,
  Checkbox,
  Chip,
} from "@mui/material";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

const cookies = new Cookies();
export default function PendapatanStatis({ global, uid, open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [gajis, setGajis] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);

  useEffect(() => {
    setSelectedAdmin([uid]);
  }, []);

  useEffect(() => {
    console.log(admins);
  }, [admins]);

  const loadAdmin = () => {
    axios({
      method: "POST",
      data: {
        session: cookies.get("session"),
      },
      url: global.api.base + "/main/admin-list",
    })
      .then((response) => {
        const res = response.data;

        if (res.error) {
          window.alert(res.message);
        } else {
          setAdmins(
            res.data.map((x) => {
              return {
                name: x.name,
                email: x.email,
                uid: x.uid,
              };
            })
          );
        }
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  useEffect(() => {
    if (open) loadAdmin();
  }, [open]);

  const [addName, setAddName] = useState("");
  const [addValue, setAddValue] = useState(0);

  const [addDisabled, setAddDisabled] = useState(false);

  useEffect(() => {
    if (addName.trim().length < 1 || addName.trim().length > 50) {
      setAddDisabled(true);
    } else if (addValue < 0) {
      setAddDisabled(true);
    } else {
      setAddDisabled(false);
    }
  }, [addName, addValue]);

  function loadData() {
    setLoading(true);
    setGajis([]);
    axios({
      method: "POST",
      url: global.api.base + "/main/pendapatan-statis-detail-from-root",
      data: {
        uid,
      },
    })
      .then((response) => {
        setLoading(false);
        const res = response.data;
        setGajis(res.pendapatans);
      })
      .catch((err) => {
        setLoading(false);
        window.alert(err.message);
      });
  }

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [open]);

  function handleValueChange(e) {
    try {
      setAddValue(parseInt(onlyNumber(e.target.value)));
    } catch (err) {
      setAddValue(0);
    }
  }

  function handleListAdd() {
    const l = [...gajis];
    l.push({
      name: addName,
      value: addValue,
    });
    setGajis(l);
    setAddName("");
    setAddValue(0);
  }

  function handleChange() {
    setLoading(true);

    Promise.all(
      selectedAdmin.map((uid) => {
        axios({
          method: "POST",
          data: {
            pendapatans: gajis,
            uid,
            session: cookies.get("session"),
          },
          url: global.api.base + "/main/ubah-pendapatan-statis",
        })
          .then((response) => {
            const res = response.data;
            if (res.error) {
              window.alert(res.message);
            } else {
            }
          })
          .catch((e) => {
            window.alert(e.message);
          });
      })
    ).then(() => {
      onClose();
      setLoading(false);
      window.alert("Success");
    });
  }

  function handleRemove(i) {
    console.log(i);
    var r = [...gajis];
    r.splice(i, 1);
    setGajis(r);
  }

  return (
    <Dialog open={open} onClose={loading ? () => {} : onClose}>
      {loading && <LinearProgress />}
      <div className="admin_dialog__selectlist">
        {admins.map((admin, index) => (
          <AdminItem
            key={index}
            admin={admin}
            selected={selectedAdmin.findIndex((x) => x === admin.uid) >= 0}
            onActiveToggle={(uid, state) => {
              console.log(
                "SELECTED: ",
                selectedAdmin.findIndex((x) => x === admin.uid) >= 0
              );

              var _selected = [...selectedAdmin];
              if (_selected.indexOf(uid) >= 0) {
                _selected.splice(_selected.indexOf(uid), 1);
              } else {
                _selected.push(uid);
              }
              setSelectedAdmin(_selected);
            }}
          />
        ))}
      </div>
      <DialogContent sx={{ minWidth: 500 }}>
        <List
          className="pendapatans"
          style={{ width: "100%", padding: "3px 2px" }}
          disablePadding
        >
          {gajis.map((gaji, index) => (
            <ListItem key={index}>
              <ListItemText>
                {gaji.name} : {formatRupiah(gaji.value, "Rp ")}
              </ListItemText>
              <ListItemButton
                onClick={handleRemove.bind(this, index)}
                sx={{
                  ml: 3,
                  width: "30px !important",
                  maxWidth: "30px !important",
                  maxHeight: "30px !important",
                  height: "30px !important",
                  padding: 0.4,
                  borderRadius: "50%",
                  backgroundColor: "rgba(255,0,0,0.7) !important",
                }}
              >
                <CloseIcon sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ mt: 0.3, mb: 0.3 }} />
        <div className="add-form-pendapatan">
          <TextField
            size="small"
            required
            variant="filled"
            label="Nama"
            helperText="Ex: bonus"
            disabled={loading}
            value={addName}
            onChange={(e) => setAddName(e.target.value)}
          />
          <TextField
            size="small"
            required
            variant="filled"
            label="Value"
            disabled={loading}
            helperText="Ex: Rp 12.000"
            value={formatRupiah(addValue, "Rp ")}
            onChange={handleValueChange}
          />
          <IconButton
            onClick={handleListAdd}
            disabled={loading || addDisabled}
            className="add-btn"
          >
            <AddIcon className="add-icon" />
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading}>Batal</Button>
        <Button disabled={loading} onClick={handleChange}>
          Ubah
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function formatRupiah(angka, prefix) {
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    const separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  const min = parseInt(angka) < 0 ? "-" : "";
  return min + prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

function onlyNumber(str) {
  return str.match(/\d/g).join("");
}

function AdminItem({ admin, onActiveToggle, selected }) {
  return (
    <Chip
      className="admin"
      label={admin.name}
      onClick={() => {
        onActiveToggle(admin.uid, undefined);
      }}
      color="secondary"
      variant={selected ? "contained" : "outlined"}
    />
  );
}
