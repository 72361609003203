import "./Akun.scss";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import BulkAdd from "./BulkAdd";
import Data from "./Data";
import Add from "./Add";
import Export from "./Export";

const cookies = new Cookies();
export default function Akun({ global }) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  function loadData() {
    setLoading(true);
    axios({
      method: "POST",
      url: global.api.base + "/main/seller-account-list",
      data: {
        session: cookies.get("session"),
      },
    })
      .then((response) => {
        const error = response.data.error;
        if (error) {
          window.alert(response.data.message);
        } else {
          setAccounts(response.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        window.alert(err.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="dashboard-content akun">
      <div
        style={{
          display: "flex",
          alignItems: "start",
          gap: 10,
          marginTop: -4,
          marginBottom: 6,
        }}
      >
        <BulkAdd global={global} onAdded={loadData} />
        <Add global={global} onAdded={loadData} />
        <Export global={global} />
      </div>
      <Data
        loading={loading}
        global={global}
        data={accounts}
        onChange={loadData}
      />
    </div>
  );
}
