import Wrapper from "./Wrapper";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./index.scss";
import Copyright from "./Copyright";
import Particles from "./Particles";
import LoginCard from "./LoginCard";
import CenterContent from "./CenterContent";

export default function Landing() {
  return (
    window.location.pathname === "/" && (
      <div className="main-landing">
        <Wrapper />
        <Copyright />
        <Particles />
        <LoginCard />
        <CenterContent />
      </div>
    )
  );
}
